<template>
  <div v-if="DownloadLink" class="scan-main" ref="scanmain">
    <div class="scan-top">
      <div class="scan-header" style="">
        <div class="header">
          <h2>View BMC</h2>
        </div>
        <div class="profile">{{ user.data.displayName }}</div>
      </div>
      <div class="alert-success">
        <div class="d-flex" style="justify-content: flex-start; align-items: center">
          <img src="../static/success.png" style="width: 18px; heigth: 18px" />
          <div style="padding-left: 27px" />
          <span class="bold-text">Success!</span>
          <span :style="ifMDandUp ? '' : 'display: none'">
            BMC QR has been successfully located on the blockchain.
          </span>
        </div>
        <button>
          <img src="../static/close_alert.png" style="width: 24px; heigth: 24px" />
        </button>
      </div>
    </div>
    <div :style="ifMDandUp ? '' : 'display: none'">
      <div style="padding-top: 35px"><h3>Result</h3></div>
      <hr />
    </div>
    <div :class="ifMDandUp ? 'bmcinformation' : 'bmcinformation-mobile'" style="flex-grow: 1">
      <div :class="ifMDandUp ? 'bmcpng' : 'bmcpng-mobile'">
        <img :src="ImagePreview" :class="ifMDandUp ? 'ticket' : 'ticket-mobile'" />
        <img src="../static/verifiedround.png" class="round-verified" />
        <div class="validbmc">This is a valid Blockchain Made Certificate</div>
      </div>
      <div :class="ifMDandUp ? 'bmcinfo' : 'bmcinfo-mobile'">
        <h3 :style="ifMDandUp ? 'margin-bottom: 20px' : 'margin-bottom: 5px'">
          BMC Information
        </h3>
        <div class="info-line flex-wrap">
          <div class="keys">ID</div>
          <div class="values">{{ FileId }}</div>
        </div>
        <div class="info-line flex-wrap">
          <div class="keys">File</div>
          <div class="values">{{ shortenedName(FileName) }}</div>
        </div>
        <div class="info-line flex-wrap">
          <div class="keys">Hash</div>
          <div class="values">
            {{ FileHash }}
          </div>
        </div>
        <div class="info-line flex-wrap">
          <div class="keys">Upload Date</div>
          <div class="values">{{ TimeStamp }}</div>
        </div>
        <div class="info-line flex-wrap">
          <div class="keys">Chain</div>
          <div class="values">{{ DisplyaName }}</div>
        </div>
        <div style="display: flex; max-width: 100%">
          <button class="downoload-origin-button">
            <a :href="DownloadLink" :download="FileName">
              Download Original BMC File
            </a>
          </button>
        </div>
      </div>
    </div>

    <div class="bmcfooter">
      <hr />
      <button class="scan-button" onClick="window.location.reload();">
        Scan Another BMC QR Code
      </button>
    </div>
  </div>
  <div
    v-else-if="FileId"
    style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      "
  >
    <div class="loader" />
  </div>
  <div v-else class="scan-main-browse">
    <div class="scan-header" style="">
      <div class="header">
        <h2>Scan BMC QR Code</h2>
        <div class="small-text" style="word-break: break-all">
          Use your camera to scan a BMC QR code to locate the original saved on blockchain
        </div>
      </div>
      <div class="profile">{{ user.data.displayName }}</div>
    </div>
    <div>
      <!-- <qrcode-capture @decode="onDecode"/> -->
      <qrcode-stream @decode="onDecode" @init="onInit" />
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";
import { mapGetters } from "vuex";
import {getFunctions,httpsCallable} from "firebase/functions";
import firebaseApp from "@/firebase";


export default {
  
  data() {
    return {
      error: "",
      FileId: "",
      DownloadLink: "",
      FileHash: "",
      FileName: "",
      FileSize: 0,
      TimeStamp: "",
      ImagePreview: "",
      uploadMainWidth: window.innerWidth,
      SaveType: "",
      chain: "",
      DisplyaName: ""
    };
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    ifMDandUp() {
      return this.uploadMainWidth >= 800;
    }
  },
  watch: {
    "$store.state.windowWidth"() {
      this.onResize();
    }
  },
  async mounted() {
    const fileId = this.$router.currentRoute.query.fileId;
    const chain = this.$router.currentRoute.query.chain;
    // console.log({chain})
    if (fileId) {
      this.DisplyaName = chain;
      // console.log(`fileId query param detected: ${fileId}. loading data...`);
      await this.onQueryFileId(fileId);
    }
  },
  beforeDestroy() {
    this.$router.replace({ query: null });
  },
  methods: {
    async onQueryFileId(fileId) {
      // console.log(`onQueryFileId(${fileId})`);
      this.FileId = fileId;
      await this.getAllData();
    },
    async onDecode(result) {
      // console.log(`onDecode(${result})`);
      this.FileId = (result.split("fileId=")[1]).split("&")[0];
      this.chain = result.split("chain=")[1];
      if (this.chain === "eth")
      {
        this.DisplyaName = "Ethereum";
      }
      else if (this.chain === "sol") { this.DisplyaName = "Solana"}
      else {this.DisplyaName = "undefined"};
      // console.log("chain = ", this.chain);
      // console.log("fileid = ", this.FileId);
      await this.getAllData();
    },
    async getAllData() {
      const functions = getFunctions(firebaseApp);
      const FindById = httpsCallable(functions, 'FindById');
      const result = await FindById({ id: this.FileId, chain: this.DisplyaName })
      // console.log("FindById() result:");
      // console.log(result.data);
      if (this.DisplyaName === "Ethereum") {
        const DocInfoById = httpsCallable(functions, 'docInfoById');
        const result2 = await DocInfoById({ fileId: this.FileId })
        // console.log("data loaded");
        // console.log("DocInfoById() result:");

        // console.log(result2.data);
        this.FileHash = result2.data.hash;
        this.TimeStamp = new Date(result2.data.stamp * 1000).toLocaleString();
      }
      else if (this.DisplyaName === "Solana")
      {
        const getSolInfo = httpsCallable(functions, 'getSolInfo');
        const result2 = await getSolInfo({ fileId: this.FileId })
        this.FileHash = result2.data.hash;
        this.TimeStamp = new Date(result2.data.timestamp * 1000).toLocaleString();
      }


      this.DownloadLink = result.data.Docu[0].DownloadLink;
      this.SaveType = result.data.Docu[0].SaveType;
      this.ImagePreview = this.SaveType.match(/\/(jpeg|jpg|gif|png)$/)
        ? this.DownloadLink
        : "https://firebasestorage.googleapis.com/v0/b/bmc-qr.appspot.com/o/default%2Fdefult.jpg?alt=media&token=e6d41b1b-db7f-4711-bed6-88741991c2fd";
      this.FileName = result.data.Docu[0].FileName;
      this.FileSize = result.data.Docu[0].FileSize;
    },
    async Getdownload() {
      const functions = getFunctions(firebaseApp);
      const getDownloadFile = httpsCallable(functions, 'getDownloadFile');
      await getDownloadFile({ DownloadLink: this.DownloadLink }).then(result => {
        // console.log(result.data.file);
      });
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error = "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
    onResize() {
      if (!this.$refs.scanmain) return;
      this.uploadMainWidth = this.$refs.scanmain.clientWidth;
    },
    getFileNameAndType(s) {
      let name = "";
      let type = "";
      if (s === "") return s;
      for (let i = s.length - 1; i >= 0; i--) {
        if (s[i] === ".") {
          name = s.slice(0, i);
          type = s.slice(i + 1, s.length);
          return { name, type };
        }
      }
      name = s;
      return { name, type };
    },
    shortenedName(s) {
      let str = this.getFileNameAndType(s);
      if (this.ifMDandUp) {
        if (s.length > 20) {
          if (str.type == "") return str.name.slice(0, 5) + "..." + str.name.slice(-6, -1);
          else return str.name.slice(0, 5) + "..." + str.name.slice(-6, -1) + "." + str.type;
        }
      } else {
        if (s.length > 10) {
          if (str.type == "") return str.name.slice(0, 3) + "..." + str.name.slice(-3, -1);
          else return str.name.slice(0, 3) + "..." + str.name.slice(-3, -1) + "." + str.type;
        }
      }
      return s;
    }
  }
};
</script>

<style scoped lang="scss">
.my-custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  content: "Browse file";
  background: #0796d0;
  border: 1px solid #f0f0f0;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  color: #ffffff;
  border-radius: 5px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 200px;
  height: 40px;
}
.my-div {
  background: #0796d0;
  border: 1px solid #f0f0f0;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  color: #ffffff;
  border-radius: 5px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 200px;
  height: 40px;
}
::-webkit-file-upload-button:focus {
  outline: none;
}
.my-custom-file-input::before {
  border-color: black;
  content: "Browse file";
  //background: #0796d0;
  // backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  // border-radius: 5px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  display: inline-block;
  width: 200px;
}
.browse-button {
  background: #0796d0;
  border: 1px solid #f0f0f0;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  color: #ffffff;
  border-radius: 5px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 200px;
  height: 40px;
}
.error {
  font-weight: bold;
  color: red;
}
.scan-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  width: 100%;
  background: #ffffff;
  padding: 50px 70px;
  @media only screen and (max-width: 799px) {
    padding: 20px 30px;
  }
}
.scan-main-browse {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  width: 100%;
  background: #ffffff;
  padding: 50px 70px;
  @media only screen and (max-width: 799px) {
    padding: 20px 30px;
  }
}
.scan-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 13px;
}
.scan-header .small-text {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #808080;
}
.scan-header h2 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
}
.scan-header .profile {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
}
.alert-success {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid rgba(243, 250, 248, 1);
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #2f7669;
  .bold-text {
    font-weight: 700;
  }
}
h3 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}
hr {
  margin: 16px;
}
.bmcinformation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //padding: 47px 0;
  //height: 100%;
}
.bmcinformation-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0 0 0;
  //height: 100%;
}
.bmcpng {
  position: relative;
  background: rgba(196, 196, 196, 0.25);
  width: 50%;
  box-sizing: border-box;
  height: 100%;
  padding: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bmcpng-mobile {
  position: relative;
  background: rgba(196, 196, 196, 0.25);
  width: 100%;
  //height: 90%;
  box-sizing: border-box;
  //height: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ticket {
  max-width: 100%;
  max-height: calc(100vh / 5);
}
.ticket-mobile {
  max-width: 100%;
  max-height: 100%;
}
.bmcinfo {
  background: rgba(232, 232, 232, 0.25);
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 33px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.bmcinfo-mobile {
  background: rgba(232, 232, 232, 0.25);
  box-sizing: border-box;
  width: 100%;
  //height: 50%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.bmcinfo .info-line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  width: 100%;
  overflow-wrap: break-word !important;
}
.keys {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #a0a0a0;
  min-width: 100px !important;
}
.values {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  word-break: break-all;
  color: #3d3d3d;
}
.downoload-origin-button {
  text-decoration: none;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  /* identical to box height */
  padding: 0 30px;
  height: 50px;
  color: #ffffff;
  background: #263853;
  border: 1px solid #f0f0f0;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  max-width: 100%;
  border-radius: 5px;
  margin-top: 20px;
  a {
    text-decoration: none;
    color: #ffffff;
  }
}
.validbmc {
  position: absolute;
  bottom: 0;
  transform: translate(0px, -13px);
  font-family: Courier Prime;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  /* identical to box height */
  color: #0fa958;
}
.round-verified {
  position: absolute;
  top: 0;
  right: 0;
  weight: 45px;
  height: 45px;
  transform: translate(-9px, 9px);
}
.bmcfooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
}
.bmcfooter .scan-button {
  width: 220px;
  height: 52px;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #0796d0;
  background: rgba(63, 209, 255, 0.07);
  border: 1px solid #0796d0;
  box-sizing: border-box;
  border-radius: 10px;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>