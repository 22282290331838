<template>
    <div class="main-dashboard">
        <div id="wrap-main">
          <div>
            <DashboardNav/>
          </div>
          <div>
            <Success />
          </div>
        </div>
    </div>
  </template>
  
  
  <script>
  
  import DashboardNav from '../components/new-components/DashboardNav.vue';
  import Success from '../components/new-components/Success.vue';

  export default {
    
    name: 'ProfileSuccessView',
    components: {
      DashboardNav,
      Success
    },
  }
  </script>
  
  <style scoped>
  
  </style>