<template>
  <div class="main-dashboard">
       <!--==================== Web Navbar =================-->
      <div class ="web-div">
          <div class="sidebar-wrap">
              <div class="sidebar-inner">
                  <router-link to="/">
                      <img src="../../assets/images/Logo-white.svg" alt="logo">
                  </router-link>
                  <div class="dashboard-list">
                      <ul class="ins-list">

                          
                          <!-- <router-link to="/register" v-if="$store.state.user.isAdmin">paginatedItems
                              <li class="side-nev-link">
                                  <span class="icon-span">
                                      <img src="../../assets/images/register.svg" alt="icon">
                                  </span>
                                  <p class="sidebar-menu">Register</p>
                              </li>
                          </router-link> -->
                          
                          <!-- <router-link to="doc-main">
                              <li class="side-nev-link" :class="{'side-nev-selected': currentRouteName == 'doc-main'}">
                                  <span class="icon-span">
                                      <img src="../../assets/images/cloud-icon.svg" alt="icon">
                                  </span>
                                  <p class="sidebar-menu">Upload Document</p>
                              </li>
                          </router-link> -->

                          <router-link to="/overview">
                              <li class="side-nev-link" :class="{'side-nev-selected': currentRouteName == 'overview'}">
                                  <span class="icon-span">
                                      <!-- <img src="../../assets/images/home-icon.svg" alt="icon"> -->
                                      <i data-feather="home" stroke="white" stroke-width= "1.5"></i>
                                  </span>
                                  <p class="sidebar-menu">Overview</p>
                              </li>
                          </router-link>
                          
                          <!-- <router-link to="/dashboard-cam">
                              <li class="side-nev-link" :class="{'side-nev-selected': currentRouteName == 'dashboard-cam'}">
                                  <span class="icon-span">
                                      <img src="../../assets/images/scan-icon.svg" alt="icon">
                                  </span>
                                  <p class="sidebar-menu">Scanner</p>
                              </li>
                          </router-link> -->
                          
                          <router-link to="/profile">
                              <li class="side-nev-link" :class="{'side-nev-selected': currentRouteName == 'profile'}">
                                  <span class="icon-span">
                                      <!-- <img src="../../assets/images/user-icon.svg" alt="icon"> -->
                                      <i data-feather="user" stroke="white" stroke-width="1.5"></i>
                                  </span>
                                  <p class="sidebar-menu">Settings</p>
                              </li>
                          </router-link>
                      </ul>
                  </div>
              
              </div>
              
              <div class="log-out-div">
                  <div class="side-nev-link" @click.prevent="signOut" role="button">
                      <span class="icon-span">
                          <!-- <img src="../../assets/images/logout-icon.svg" alt="icon"> -->
                          <i data-feather="log-out" stroke="white" stroke-width= "1.5"  class="flip"></i>
                      </span>
                      <p class="sidebar-menu">Log Out</p>
                  </div>
              </div>
          </div>
          
      </div>

      <!--==================== Mobile Navbar =================-->
      <div class="mob-div">
          <nav role="navigation" class="navigation">
              <div class="navigation__logo">
              <router-link to="/">
                  <img src="../../assets/images/Logo-white.svg" alt="logo">
              </router-link>
              </div>
              <div class="navigation__wrapper">
              <input type="checkbox" id="hamburger">
              <label for="hamburger" class="navigation__button">
                  <span></span>
                  <span></span>
                  <span></span>
              </label>
              
              <ul class="navigation__links ins-list">
                  <router-link to="/overview">
                      <li class="side-nev-link">
                          <span class="icon-span">
                              <img src="../../assets/images/home-icon.svg" alt="icon">
                          </span>
                          <p class="sidebar-menu">Overview</p>
                      </li>
                  </router-link>

                  <!-- <router-link to="dashboard">
                      <li class="side-nev-link">
                          <span class="icon-span">
                              <img src="../../assets/images/cloud-icon.svg" alt="icon">
                          </span>
                          <p class="sidebar-menu">Upload Document</p>
                      </li>
                  </router-link> -->

                  <!-- <router-link to="/dashboard-cam">
                      <li class="side-nev-link">
                          <span class="icon-span">
                              <img src="../../assets/images/scan-icon.svg" alt="icon">
                          </span>
                          <p class="sidebar-menu">Scanner</p>
                      </li>
                  </router-link> -->
                  <router-link to="/profile">
                      <li class="side-nev-link">
                          <span class="icon-span">
                              <img src="../../assets/images/user-icon.svg" alt="icon">
                          </span>
                          <p class="sidebar-menu">Settings</p>
                      </li>
                  </router-link>
              </ul>
              
              </div>
          </nav>
      </div>
      
  </div>
  
  
</template>

<script>
    import {getAuth, signOut} from "firebase/auth";
    import feather from 'feather-icons'

    export default {
        name: 'DashboardNav',
        mounted() {
            feather.replace();
        },
        methods: {
            signOut() {
                const auth = getAuth();
                signOut(auth).then(() => {
                    this.$router.push({
                        name: "login",
                    });
                });
            }
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        }
    }
</script>

<style scoped>
.navigation__links.ins-list {
    z-index: 1000;
}
.flip {
  transform: rotate(180deg);
}
.sidebar-wrap{
  background: url(../../assets/images/sidebar-bg.svg) no-repeat;
  height: 100vh;
  background-size: cover;
}
.sidebar-inner{
  padding: 30px 0 0 0;
}
.sidebar-menu{
  font-weight: 700;
  font-size: 13px;
  color: #F0F0F0;
}
.side-nev-link{
  padding: 10px 0 10px 20px;
  width: 200px;
  margin-top: 10px;
}
.side-nev-link:hover{
  background: #263853;
  border-radius: 5px;
}
.side-nev-selected{
  background: #263853;
  border-radius: 5px;
}
.log-out-div .side-nev-link{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.log-out-div .side-nev-link:hover{
  background: transparent;
}
</style>