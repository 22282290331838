<template>
  <div>
    <NavBarVue/>
    <div class="main-body-div2 pt-3 pt-md-5">
      <div class="terms-main-main">
        <div @click="$router.go(-1)" class="mb-3">
          <!-- <img src="../assets/images/Left-arrow-gray.svg" alt=""> -->
          <i data-feather="chevron-left" stroke="gray" stroke-width="3" height="18"></i>
          <button class="gray-btn ps-2">Back to previous</button>
        </div>
        <h3 class="pb-3">BLOCKCHAIN MADE CERTIFICATE (BMC FZCO) Terms and conditions</h3>
        <p class="font-12 pb-3">
          (Last Updated June 10, 2022)
        </p>
        <h5 class="pb-1">Introduction</h5>
        <p class="font-12 pb-3">
          Personal data collected by Blockchain Made Certificate (BMC FZCO) and our partners are processed in accordance with the law on legal protection of personal data and other legal acts of every country we aim to do business. In terms of the personal information we process and store, it's limited to your first name, last name, and email address. All personal data collected from this site complies with the principles of the General Data Protection Regulation (EU GDPR)
        </p>
        <p class="font-12 pb-3">
          The California Consumer Privacy Act of 2018 (CCPA), The California Online Privacy Protection Act of 2003 (CALOPPA), Personal Information Protection and Electronic Documents Act (PIPEDA), The Privacy and Electronic Communications Regulations (PECR) and in line with the transparency requirements of Articles 9 and 10 of the Dubai International Financial Centre Data Protection Law, DIFC Law No. 5 of 2020 (the “DIFC-DPL). By accessing this site you agree to the terms of this privacy policy and consent to the collection, processing, use or transfer of data as set out in this policy
        </p>
        <p class="font-12 pb-3">
          This policy (together with our Terms and Conditions) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By continuing to visit our website https://www.bmcdubai.com/, mobile application, and other BMC FZCO services, you accept and consent to the practices described in this policy.
        </p>
        <h5 class="pb-1">Information Collection</h5>
        <p class="font-12 pb-3">
          When you interact with the Site or its Services, BMC FZCO may collect Personal Data and other information from you, as further described below-
        </p>
        <p class="font-12 pb-3">
          Personal Data you provide to us. We collect Personal Data from you when you voluntarily provide such information.  This can occur in several instances, such as when you send us a message relating to our Services and provide your name and email address when contacting us with inquiries.  Wherever BMC FZCO collects your Personal Data, we make an effort to provide a link to this Privacy Policy.
        </p>
        <p class="font-12 pb-3">
          By voluntarily providing us with Personal Data, you are consenting to our use of it as per this Privacy Policy.  If you provide any Personal Data, you acknowledge and agree that such Data may be transferred from your current location to the offices and servers of BMC FZCO and its authorized third parties.
        </p>
        <h5 class="pb-1">Background checks</h5>
        <p class="font-12 pb-3">
          When you register as a user, we may use a partnering third-party service to perform a background check on you with your consent. We do not receive or store any information from the background check other than an indicator of whether you meet our requirements or not.
        </p>
        <h5 class="pb-1">Non-Identifiable or Aggregated Data</h5>
        <p class="font-12 pb-3">
          When you interact with BMC FZCO and users through our website App or Services, we receive and store certain personal non-identifiable information.  This information is collected passively and cannot presently be used to specifically identify you as a person.  We may store such information or such information may be included in information databases that are owned and maintained by BMC FZCO, its affiliates, agents or service providers.  We also use "cookies" to collect personal non-identifiable information, such as your IP address, browser type, domain name, and the BMC FZCO website pages that you visit.  More information concerning cookies is provided below-
        </p>
        <h5 class="pb-1">Cookies</h5>
        <p class="font-12 pb-3">
          Cookies are pieces of information that a website transfers to your computer's hard drive for record-keeping purposes.  We use cookies to track your personal preferences and purchases as you navigate through our websites to save you from having to re-enter information every time you visit BMC FZCO making your next shopping experience at a BMC FZCO website improved and better.  To search our websites BMC FZCO you need to have the cookies option turned on your web browser.  Please reference your particular browser's instructions regarding the cookies option.  If you choose to disable the cookies option on your web browser, you may be limited in your ability to use some or all of the features provided on BMC FZCO.
        </p>
        <h5 class="pb-1">GDPR Data Protection Procedure</h5>
        <p class="font-12 pb-3">
          By using BMC FZCO, you agree to the collection, transfer, storage and use of your personal information by BMC FZCO on servers located in United Kingdom as further described in our Privacy Notice. You and BMC FZCO shall process personal data received under and/or in connection with this User Agreement each as a separate and independent controller. In no event will BMC FZCO and you process personal data under and/or in connection with this User Agreement as joint controllers or in a controller-to-processor relationship. As such separate and independent controllers, you shall be individually and separately responsible for complying with the obligations that apply to you as a controller under applicable data protection law.
        </p>
        <p class="font-12 pb-3">
          You shall comply with your obligations under applicable data protection law (including but not limited to the Regulation (EU) 2016/679 (EU General Data Protection Regulation “GDPR”)) and supplementing EU and local data protection law. This includes, but is not limited to the obligation to provide appropriate safeguards for the transfer of personal data to a third country or an international organization. You shall process personal data received from BMC FZCO exclusively for the purpose(s) you received the personal data under and/or in connection with this User Agreement. You shall erase the personal data received under this User Agreement immediately after the respective purpose(s) has/have been fulfilled. Any further processing of the personal data is not permitted except when legally required (e.g., if a retention obligation applies).
        </p>
        <p class="font-12 pb-3">
          Failure to abide with the aforementioned obligations may result in disciplinary action up to and including account suspension.
        </p>
        <h5 class="pb-1">California Consumer Privacy Act Regulations (CCPA)</h5>
        <p class="font-12 pb-3">
          This privacy notice for California residents (“Privacy Notice“) is for Californian residents regarding their personal information and privacy rights and addresses the specific requirements under the California Consumer Privacy Act of 2018 (Cal. Civ. §§ 1789.100–1798.199) and the California Consumer Privacy Act Regulations by the Attorney General (the “CCPA“). The act creates new rights for residents and users of California.
        </p>
        <p class="font-12 pb-3">
          <strong>Blockchain Made Certificate (BMC FZCO)</strong>
          , is an award-winning KYC and legal compliance tool using the latest technology to provide a better, faster  more cost-effective solution for large enterprise corporate clients In terms of the personal information we process it's essentially limited to your first name, last name, and email address. Whilst we do process some location-related information pertaining to individuals it's near impossible to verify if this is where a person is actually a resident or if it's the location of where they work. <strong>BMC FZCO</strong> will aim to treat any such data as pertaining to Californian residents. Therefore, any Californian residents who wish to opt out of <strong>BMC FZCO</strong> may do so by contacting our support via admin@bmcdubai.com.
        </p>
        <h5 class="pb-1">
          Rights under the CCPA
        </h5>
        <p class="font-12 pb-3">
          Unless the CCPA law is updated, residents will have full CCPA rights whether their information was processed in a business context or not.
        </p>
        <h5 class="pb-1">
          Access to Personal Information
        </h5>
        <p class="font-12 pb-3">
          You may request that we disclose to you the categories and specific pieces of Personal Information that we have collected about you, the categories of sources from which your Personal Information is collected, the business or commercial purpose for collecting your Personal Information, the categories of Personal Information that we disclosed for a business purpose, any categories of Personal Information about you that we sold, the categories of third-parties with whom we have shared your Personal Information, and the business or commercial purpose for selling your Personal Information, if applicable.
        </p>
        <h5 class="pb-1">
          Deletion requests
        </h5>
        <p class="font-12 pb-3">
          You have the right to request that we delete any Personal Information collected from you and retained unless retaining the information is necessary for us or for our service providers to: Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities. Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546).Comply with a legal obligation. Once we receive and confirm your verifiable user request, we will delete your Personal Information, unless an exception applies. 
        </p>
        <h5 class="pb-1">
          Right to opt-out
        </h5>
        <p class="font-12 pb-3">
          You have the right, at any time, to direct us not to sell your personal information to third parties, as defined under the CCPA. This is also referred to as your right to opt-out. You have the right to opt-out of sales, within the meaning of the CCPA, of certain information subject to the CCPA. Our Site and Services are meant for adults. We do not knowingly collect personally identifiable information from children under 13. If you are 16 years of age or older, under the CCPA, you have the right to direct us to not sell your personal information any time (the right to opt-out). Response timing and format Our goal is to respond to a verifiable request within 7 days of its receipt. If we require more time, we will inform you of the reason and extension period in writing within the 7 days period. If you are a User, we will deliver our written response to you through your Customer account. If you are a Site visitor, we will deliver our response by mail or electronically, at your option. Any disclosures we provide will cover only the 12-month period preceding the request. If reasonably possible, we will provide your Personal Information in a format that is readily useable and should allow you to transmit the information without hindrance. In case of rejection, the response we provide will explain the reasons for which we cannot comply with your request. Please note that these CCPA rights are not absolute and requests are subject to any applicable legal requirements, including legal and ethical reporting or document retention obligations.
        </p>
        <p class="font-12 pb-3">
          To contact <strong>BMC FZCO</strong> regarding any privacy matters please email admin@bmcdubai.com. <strong>Notice For California Consumers – Your Privacy Rights under the (CalOPPA)</strong> The California Online Privacy Protection Act, more commonly referred to as CalOPPA, was drafted to protect the privacy rights and personal data of California residents. Under California’s “shine the light” law, California residents have the right to request in writing from businesses with whom they have an established business relationship;
        </p>
        <p class="font-12 pb-3">
          A list of the categories of personal information, such as name and e-mail address, and the type of services provided to that customer, that a business has disclosed to third parties (including affiliates that are separate legal entities) during the immediately preceding calendar year for the third parties’ direct marketing purposes, and
        </p>
        <p class="font-12 pb-3">
          The names and email addresses of all such third parties. To request the above information, please e-mail us at admin@bmcdubai.com. We will respond to such written requests within 7 days following receipt at the mailing address above. We reserve the right not to respond to requests submitted other than to the address specified above or otherwise exempted by law. Please note that we are required only to respond to each user once per calendar year.
        </p>
        <h5 class="pb-1">
          Personal Information We Collect About You (PIPEDA)
        </h5>
        <p class="font-12 pb-3">
          According to the Personal Information Protection and Electronic Documents Act, 2000 (PIPEDA), “personal information” means information about an identifiable individual. The information you provide to BMC FZCO is considered personal information, it is possible to relate it back to you through your name, e-mail address and any other information that can identify you, if information cannot be related to an identifiable individual it is considered anonymous. Failure to provide all information required by BMC FZCO may prevent access to any or all BMC FZCO materials, and failure to maintain accurate information may also result in suspension or termination of access to any or all BMC FZCO materials and services.
        </p>
        <h5 class="pb-1">
          Rights of Individuals in Relation to Their Personal Data  Dubai International Financial Centre Data Protection Law (DIFC-DPL)
        </h5>
        <p class="font-12 pb-3">
          The DIFC-DPL provides certain rights to data subjects in relation to their personal data. These include the rights to: Request details about the personal data that we process, and obtain a copy of the data that we hold about them (to the extent this is not in breach of a legal obligation of professional secrecy to which we are subject in relation to client data entrusted to us and that would, therefore, prevent us from informing the relevant data subjects) 
        </p>
        <p class="font-12 pb-3">
          Correct or update their personal data subject to the above Port personal data that the data subject has provided to us, in machine readable format, to another supplier 
        </p>
        <p class="font-12 pb-3">
          Erase the data that we hold about them in some cases
        </p>
        <p class="font-12 pb-3">
          Restrict or object to its processing in some cases
        </p>
        <p class="font-12 pb-3">
          Object to processing:
        </p>
        <p class="font-12 pb-3">
          Based on grounds relating to the individual’s particular situation, where the processing is based on the legitimate interest of BMC FZCO or our clients 
        </p>
        <p class="font-12 pb-3">
          Where personal data is being processed for direct marketing purposes
        </p>
        <p class="font-12 pb-3">
          Where any decision based solely on automated processing, including Profiling, produces legal consequences concerning them or other seriously impactful consequences and to require such decision to be reviewed manually. 
        </p>
        <p class="font-12 pb-3">
          Not be discriminated against in pricing and legal services for exercising any of their rights and, for the avoidance of doubt, unless permitted by the DIFC-DPL, BMC FZCO will not: 
        </p>
        <p class="font-12 pb-3">
          Deny data subjects use of our legal services;
        </p>
        <p class="font-12 pb-3">
          Charge different prices or rates for our services, including through granting discounts or other benefits, or imposing penalties;
        </p>
        <p class="font-12 pb-3">
          Provide data subjects with a different level or quality of legal services in exchange for the retention or use of any personal data that data subjects have provided to us; and 
        </p>
        <p class="font-12 pb-3">
          Suggest that data subjects may receive a different price or rate for our services or a different level or quality of services. 
        </p>
        <p class="font-12 pb-3">
          Where consent is the basis for processing their personal data, the individual may decline to give his or her consent, or to withdraw consent to the processing at any time.
        </p>
        <p class="font-12 pb-3">
          These rights are not absolute and are subject to various conditions under applicable data protection and privacy legislation and the laws and regulations to which we are subject in the performance of legal services.
        </p>
        <p class="font-12 pb-3">
          In some cases, the exercise of these rights (for example, erasure, objection, restriction or the withholding or withdrawing of consent to processing) may make it impossible for us to achieve the purposes identified in Section 3 of this Privacy Notice and provide effective legal services.
        </p>
        <p class="font-12 pb-3">
          The processing of requests for action by BMC FZCO in regard to the exercise of a data subject’s rights under the DIFC-DPL is overseen by an internal team consisting of the Manager, the Office of General Counsel, the local data privacy contact and other professionals needed to respond to the particular request.
        </p>
        <p class="font-12 pb-3">
          Any individual wishing to assert his or her rights under the DIFC-DPL should address the relevant request to: admin@bmcdubai.com
        </p>
        <h5 class="pb-1">
          The Privacy and Electronic Communications Regulations (PECR) give you the right:
        </h5>
        <p class="font-12 pb-3">
          PECR restrict unsolicited marketing by phone, fax, email, text, or other electronic message. BMC FZCO will obtain valid consent from clients to tick opt-in boxes confirming they are happy to receive marketing calls, texts or emails from us. 
        </p>
        <p class="font-12 pb-3">
          To choose whether or not to receive marketing communication from us by email, text or telephone. Please see the Marketing Permissions Section below for more information about how to change or update your contact permissions and preferences 
        </p>
        <p class="font-12 pb-3">
          To know about the cookies BMC FZCO uses, so that you can decide whether to give us permission to store a cookie on your device. Please see the Cookies section for more information
        </p>
        <h5 class="pb-1">Advertising Networks</h5>
        <p class="font-12 pb-3">
          From time to time, we may use one or more third-party online advertising networks to serve ads on our behalf, onto the Internet.  Said third-party network may collect anonymous information about your visits to our website and record your interaction with our ads.  This accomplished with features such as cookies, action tags, web beacons, and/or GIF tags located within BMC FZCO. Occasionally, we may share anonymous information with third-party networks, in an aggregate form, to help us analyze and improve our website for future use.  These third-party networks may also use information about your visit to our website and other websites in order to provide ads about goods and services that may strike your interest.
        </p>
        <h5 class="pb-1">
          Marketing information:
        </h5>
        <p class="font-12 pb-3">
          If you have elected to receive marketing emails from us, we retain information about your marketing preferences for a reasonable period of time from the date you last expressed interest in our services, such as when you last opened an email from us or ceased using your our materials. We retain information derived from cookies and other tracking technologies for a reasonable period of time from the date such information was created.
        </p>
        <h5 class="pb-1">
          Children Under Age 
        </h5>
        <p class="font-12 pb-3">
          BMC FZCO is directed to adults and does not knowingly collect personal from children under the age of 13.  If you are a child under the age of 13, you must have your parent's or guardian's consent, and provide said consent to BMC FZCO. If you are the parent or guardian of child under the age of 18 and you believe your child has provided personal information BMC FZCO, please email us at admin@bmcdubai.com
        </p>
        <h5 class="pb-1">
          Children’s online privacy protection act 
        </h5>
        <p class="font-12 pb-3">
          We comply with the Children’s Online Privacy Protection Act of 1998 (“COPPA”). COPPA requires that web site operators never knowingly request personally identifiable information from anyone under the age of 13 without requesting verifiable parental consent.
        </p>
        <p class="font-12 pb-3">
          In accordance with COPPA, we do not knowingly collect any personally identifiable information from children under the age of 13. We recommend that no one under the age of 15 submit personally identifiable information to our Site. In the event that we determine that a user of this Site is under the age of 13, we will not maintain or use his or her personally identifiable information.
        </p>
        <h5 class="pb-1">
          Information Use
        </h5>
        <p class="font-12 pb-3">
          The information we collect on BMC FZCO may be used to fulfill your requests or communicate special offers and other BMC FZCO promotions to you.  When you contact us, we give you the opportunity to receive notifications about special offers and other BMC FZCO promotions.    
        </p>
        <h5 class="pb-1">
          Information Disclosure
        </h5>
        <p class="font-12 pb-3">
          We may disclose the information we collect about you (including personally identifiable information) to third parties in order to comply with certain laws, regulations, and/or governmental requests.  We may also disclose such information for the following purposes:  to verify or enforce compliance with our User Agreement and Privacy Policy, to process your request(s), to reduce credit risk, to protect against fraud/misuse/unauthorized use of the Site, or when we believe in good faith that the law requires such disclosure.  Furthermore, we may also disclose such information to a successor entity in connection with a merger, consolidation, sale of assets or other corporate change affecting BMC FZCO.
        </p>
        <h5 class="pb-1">
          Information Sharing
        </h5>
        <p class="font-12 pb-3">
          We may share the information collected on BMC FZCO website with agents or contractors who provide support for BMC FZCO's internal operations. Said parties may receive personally identifiable information- as such is needed to perform their duties. Please be assured that said parties may not use the information for any other purpose. If you do not want us to share such information, please email us at admin@bmcdubai.com
        </p>
        <p class="font-12 pb-3">
          Where we store your information BMC FZCO has a data privacy officer who is responsible for ensuring that your personal information is collected, used, disclosed (shared) and retained in compliance with applicable privacy regulations. However, the data that we collect from you may be transferred to, and stored at, a destination outside the European economic area ("EEA") that may not be subject to equivalent data protection law.
        </p>
        <p class="font-12 pb-3">
          Where your information is transferred outside the EEA, we will take all steps reasonably necessary to ensure that your data is subject to appropriate safeguards, such as relying on a recognized legal adequacy mechanism, and that it is treated securely and in accordance with this privacy policy.
        </p>
        <h5 class="pb-1">
          Securing Personal Data
        </h5>
        <p class="font-12 pb-3">
          BMC FZCO takes reasonable steps to protect Personal Data via the Site from loss, misuse, and unauthorized access, disclosure, alteration, or destruction.  But unfortunately, no electronic transmission of information via the internet is ever fully secure- carefully decide whether you want to send us information electronically before actually doing so.
        </p>
        <h5 class="pb-1">
          Your Rights As A Data Subject
        </h5>
        <p class="font-12 pb-3">
          In some jurisdictions (for example, the member states of the European Union) you may be entitled to certain rights in and to your personal data, subject to certain conditions and exceptions contained in applicable law. These rights may include the following:Request us to confirm whether your personal data is processed by us, and if we do, to obtain access to your personal data and certain information about it. 
        </p>
        <p class="font-12 pb-3">
          Require the correction of your personal data if it is inaccurate or incomplete.<br/>
          Direct us to stop processing your personal data under certain circumstances.<br/>
          Erase or delete your personal data, for example, where the data is no longer needed to achieve the purpose for which it was collected.<br/> 
          Restrict the further processing of personal data<br/>
          Request us not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affects you (we currently do not engage in such processing and will notify you prior to doing so). <br/>
          Request to receive your personal data for transmission to, or to directly transmit to, another data controller in a structured, commonly-used and machine-readable format.<br/>
          To protect your privacy and the security of your personal data, we will take reasonable steps to verify your identity before complying with such rights requests.
        </p>
        <h5 class="pb-1">Privacy shield</h5>
        <p class="font-12 pb-3">
          BMC FZCO adheres to the seven privacy shield principles of notice, choice, and accountability for onward transfer, security, data integrity, access, and recourse, enforcement and liability as they relate to personal data. BMC FZCO verifies compliance to the principles through self-assessment. The privacy policy covering human resources data can be accessed on our intranet web site by all employees.
        </p>
        <h5 class="pb-1">Legal bases for processing EEA users Data</h5>
        <p class="font-12 pb-3">
          If you are an individual in the European economic area (EEA), we collect and process information about you only where we have legal bases for doing so under applicable EU laws.  The legal bases depend on the services you use and how you use them. This means we collect and use your information only where:
        </p>
        <p class="font-12 pb-3">
          We need it to provide you the services, including to operate the services, provide customer support and personalized features and to protect the safety and security of the services; 
        </p>
        <p class="font-12 pb-3">
          It satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to market and promote the services and to protect our legal rights and interests;
        </p>
        <p class="font-12 pb-3">
          You give us consent to do so for a specific purpose; or<br/>
          We need to process your data to comply with a legal obligation.
        </p>
        <p class="font-12 pb-3">
          If you have consented to our use of information about you for a specific purpose, you have the right to change your mind at any time, but this will not affect any processing that has already taken place.  Where we are using your information because we or a third party (e.g. your employer) have a legitimate interest to do so, you have the right to object to that use though, in some cases, and this may mean no longer using the services
        </p>
        <h5 class="pb-1">
          Your right to withdraw consent
        </h5>
        <p class="font-12 pb-3">
          Where the processing of your personal information by us is based on consent, you have the right to withdraw that consent without detriment at any time by contacting us. You can exercise the rights listed above at any time by contacting us. If your request or concern is not satisfactorily resolved by us, you may contact our data protection officer, via email admin@bmcdubai.com.
        </p>
        <p class="font-12 pb-3">
          He can provide further information about your rights and our obligations in relation to your personal data, as well as deal with any complaints that you have about our processing of your personal data.
        </p>
        <h5 class="pb-1">Opting out of messages from us</h5>
        <p class="font-12 pb-3">
          To opt-out of any future promotional messages from us, you should unsubscribe in the body of the promotional message that was sent to you (found at the bottom of the email) or send an unsubscribe request to us at admin@bmcdubai.com. We will process your request within a reasonable time after receipt.
        </p>
        <h5 class="pb-1">
          Opting out of interest based advertising
        </h5>
        <p class="font-12 pb-3">
          The online advertising industry provides a service through which you may opt-out of receiving targeted ads from certain data partners and other advertising partners that participate in self-regulatory programs. We comply with the Self-Regulatory Principles for Online Behavioral Advertising set forth by the Digital Advertising Alliance (DAA) and the European Interactive Digital Advertising Alliance ("EDAA"). You can opt-out of targeted advertising from certain providers at www.aboutads.info/consumers. Please note that by opting out, you will continue to see generic advertising that is not tailored to your specific interests and activities.
        </p>
        <p class="font-12 pb-3">
          Please note that cookie-based opt-outs must be performed on each device and browser that you wish to have opted-out. For example, if you have opted out on your device browser, that opt-out will not be effective on your mobile device. You must separately opt out on each device. EU residents who have provided their consent to our use of cookies and similar technologies can use the EDAA’s opt out tool which can be found at BMC FZCO.
        </p>
        <h5 class="pb-1">
          Data portability
        </h5>
        <p class="font-12 pb-3">
          Data portability is the ability to obtain some of your information in a format you can move from one service provider to another (for instance, when you transfer your mobile phone number to another carrier).  Depending on the context, this applies to some of your information, but not to all of your information.  Should you request it, we will provide you with an electronic file of your basic account information and the information you create on the spaces you under your sole control, like your personal bitbucket repository.
        </p>
        <h5 class="pb-1">
          Additional EU Disclosures
        </h5>
        <p class="font-12 pb-3">
          We generally act as the data controller of your information submitted through our website and platform; however, under certain circumstances, User or Corporate entity may engage us to provide them with certain processing services related to information owned or controlled by the User or Corporate entity. We are not responsible for any User or Corporate entity's use of information for which it is an owner or controller. As processor for a User or Corporate entity, we only act under their instruction with respect to the processing of your information. To learn more about how a User or Corporate entity may use such information, you should review its privacy policy.
        </p>
        <p class="font-12 pb-3">
          If you are situated in the EU and have any complaints regarding our privacy practices as data controller, you have the right to make a complaint at any time to your local Supervisory Authority. We would, however, appreciate the chance to address your concerns before you approach your Supervisory Authority so please contact us in the first instance. If you are situated in the EU and have a complaint, please contact our privacy team
        </p>
        <h5 class="pb-1">
          Provision of Personal Data and failure to provide Personal Data
        </h5>
        <p class="font-12 pb-3">
          Where we need to collect Personal Data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with our services). In this case, we will not be able to provide services to you
        </p>
        <h5 class="pb-1">
          How long we keep information
        </h5>
        <p class="font-12 pb-3">
          How long we keep information we collect about you depends on the type of information, as described in further detail below.<br />
        </p>
        <p class="font-12 pb-3">
          After such time, we will either delete or anonymize your information or, if this is not possible (for example, because the information has been stored in backup archives), then we will securely store your information and isolate it from any further use until deletion is possible. 
        </p>
        <h5 class="pb-1">
          Complaint to a Data Protection Authority
        </h5>
        <p class="font-12 pb-3">
          You have the right to submit a complaint concerning our data processing activities to our data protection officer 
        </p>
        <h5 class="pb-1">
          Complaints or Queries 
        </h5>
        <p class="font-12 pb-3">
          If you have any queries regarding our data collection and protection practices or your rights, please do not hesitate to contact our data protection officer, at admin@bmcdubai.com
        </p>
        <h5 class="pb-1">Privacy Policy Updates</h5>
        <p class="font-12 pb-3">
          We may revise this privacy policy from time to time, and will post the most current version on our web site. If a revision meaningfully reduces your rights, we will notify you.
        </p>
      </div>
    </div>
  </div>
</template>


<script>

import NavBarVue from '../components/new-components/NavBar.vue';
import feather from 'feather-icons';
export default {
  
  name: 'TermCondition',
  components: {
      NavBarVue,
  },
  mounted() {
    feather.replace();
  },
}
</script>

<style scoped>
</style>