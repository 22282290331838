<template>
  <div>
    <NavBarVue/>
    <div class="main-body-div">
        <div class="region-main">
          <div class="pb-3">
            <p class="font-14 pb-2">
              <b>Issuing Country/Region</b>
            </p>
            <div class="radio-div-slct selct-field">
              <b-form-select class="form-select selct-opt" v-model="selected" ><br>
                <option :value="null" disabled>Please select an option</option>
                <option v-for="option in options" :value="option.code">
                  {{ option.name }}
                </option>
              </b-form-select>
              
            </div>
          </div>
          
          <div class="pt-3 pb-2">
            <div>
              <p class="font-14">
                <b>Select Identity Type</b>
              </p>
              <p class="font-10 pb-3 pt-1">
                  This should be a government-issued photo identity.
              </p>
              <b-form-group>
                <div :class="checked === 'passport' ? 'h-auto' : 'radio-div'">
                  <b-form-radio 
                    v-model="checked"
                    name="radio-size"
                    value="passport"
                    class=""
                  >
                  <div class="radio-inner">
                    <p class="font-12">
                      <b>PASSPORT</b>
                    </p>
                  </div>
                    
                  </b-form-radio>
                </div>

                <div class="mt-3" :class="checked === 'license' ? 'h-auto' : 'radio-div'">
                  <b-form-radio 
                  v-model="checked"
                  name="radio-size"
                  value="license"
                  class=""
                  >
                  <div class="radio-inner">
                    <p class="font-12">
                      <b>DRIVER'S LICENSE</b>
                    </p>
                  </div>
                  </b-form-radio>
                  
                </div>
              </b-form-group>
            </div>
          </div>
          
          <div class="btn-div mt-2">
            <router-link :to="{name: 'document-verification'}" class="btn-width">
                <button 
                  class="main-btn"
                  :disabled="(checked === '' || selected === null)"
                >
                  Continue
                </button>
            </router-link>
          </div>
          
        </div>
    </div>
    <FooterCompVue/>
  </div>
</template>


<script>

import NavBarVue from '../components/new-components/NavBar.vue';
import FooterCompVue from '../components/new-components/FooterComp.vue';
import { mapGetters } from "vuex";
import countries from "../assets/countries.json";

export default {
  name: 'DocumentInfo',
  computed: {
    ...mapGetters({
      kycData: "kycData",
    }),
  },
  components: {
    NavBarVue,
    FooterCompVue
  },

  
  data() {
    return {
      selected: null,
      options: countries,
      checked : ''
    }
  },
  mounted() {
    console.log('this.kycData.region', this.kycData.region)
    this.checked = (localStorage.getItem("checked") !== undefined && localStorage.getItem("checked") !== "null") ? localStorage.getItem("checked") : '';
    this.selected = this.kycData.region !== undefined ? this.kycData.region : null;
  },
  methods: {
    
  },
  watch: {
    checked(value) {
      localStorage.setItem("checked", value)
    },
    async selected(value) {
      this.kycData.region = value;
      await this.$store.commit("SET_KYC_DATA", this.kycData);
    }
  },
}
</script>

<style scoped>
</style>