<template>
    <div class="main-dashboard">
        <div id="wrap-main">
          <div>
              <DashboardNav/>
          </div>
          <div>
            <Profile />
          </div>
            
        </div>
    </div>
    
  </template>
  
  
  <script>
  
  import DashboardNav from '../components/new-components/DashboardNav.vue';
  import Profile from '../components/new-components/Profile.vue';

  export default {
    
    name: 'ProfileView',
    components: {
        DashboardNav,
        Profile
    },
  }
  </script>
  
  <style scoped>
  
  </style>