<template>
    <div class="cam-main-dashboard">

        <!-- main body tag -->
        <div class="body-div">
            <div class="main-uploadDoc">

                <!---------------- header Start----------------->
                <div class="dashboard-header">
                    <div class="heading-area">
                        <HeadDropdown/>
                        <h2>KYC User Database</h2>
                    </div>
                </div>
                <!---------------- header End----------------->


                <!-- Inner body tag -->
                <div class="pt-4" v-if="!loading">
                    <div class="input-group rounded position-relative">
                        <input v-model="searchText" type="search" class="form-control rounded ps-5 bg" placeholder="Search the user database" aria-label="Search" aria-describedby="search-addon" />
                        <img src="./../../assets/images/search.svg" class="position-absolute pt-3 ps-3 zIndex" alt="">
                    </div>
                    <div class="table-responsive  pt-4 user-table">
                        <table class="table text-start" id="user-table">
                            <thead class="tableHeading">
                                <tr class="border-bottom">
                                <th scope="col">ID</th>
                                <th scope="col">Date</th>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">Email Address</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Country</th>
                                <th scope="col">Status</th>
                                <th></th>
                                </tr>
                            </thead>
                            
                            <tbody v-if="this.users.length > 0">
                                <tr class="t-row" :key="index" v-for="(user, index) in paginatedUsers">
                                    <td scope="row">{{user.id.substring(0,6)}}</td>
                                    <td>{{user.date | formatDate }}</td>
                                    <td>{{user.documents.firstname}}</td>
                                    <td>{{user.documents.lastname}}</td>
                                    <td>{{user.documents.email}}</td>
                                    <td>{{user.documents.phone}}</td>
                                    <td>{{user.documents.country}}</td>
                                    <td v-b-tooltip="user.documents.declined_reason" data-placement="top" :class="user.documents.event !== 'verification.declined' ? 'text-green' : 'text-red'">{{user.documents.event == 'verification.declined' ? 'Failed' : 'Verified'}}
                                        <img v-show="user.documents.event == 'verification.accepted'" src="../../assets/images/check-circle.svg" alt="icon" />
                                    </td>
                                    <td v-show="user.documents.event == 'verification.accepted'">
                                        <router-link :to="'view-qr/'+user.id">
                                            <u class="text-blue">
                                                View
                                            </u>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!--------------- Pegination area --------------->
                    <div class="pegi-main pt-5">
                        <div class="pegi-dropdown">
                            <b-dropdown toggle-class='customDropdown' variant='none' id="dropdown-1" :text="pageSize.toString()">
                                <b-dropdown-item @click="sizeChanged(10)">10</b-dropdown-item>
                                <b-dropdown-item @click="sizeChanged(25)">25</b-dropdown-item>
                                <b-dropdown-item @click="sizeChanged(50)">50</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <b-pagination v-model="currentPage" :total-rows="filteredUsers.length" :per-page="pageSize"></b-pagination>
                        <div class="pegi-search-num pegi-search">Results {{((currentPage*pageSize)-pageSize+1)}} - {{(currentPage*pageSize >= filteredUsers.length ? filteredUsers.length : (currentPage*pageSize))}} of {{filteredUsers.length}}</div>
                    </div>
                   
                </div>
                <template v-if="loading">
                    <div class="main-body-div">
                        <div class="navbar-main">
                        <b-container class="pt-4 centered">
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                            <div>
                            <p class="font-12 text-center">Loading...</p>
                            </div>
                        </b-container>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>

</template>
  
  
<script>
  import HeadDropdown from '../new-components/headDropdown.vue';
  import axios from 'axios';
  
  export default {

    name: 'UploadTable',
    components: {
        HeadDropdown
    },
    data() {
        return {
            searchText: '',
            currentPage: 1,
            pageSize: 10,
            users: [],
            loading: true
        }
    },
    filters: {
        formatDate(date) {
            const d = new Date(
                date._seconds * 1000 + date._nanoseconds / 1000000
            )
            const options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }
            return d.toLocaleDateString('en-GB', options)
        }
    },
    mounted() {
        this.getUsers();
    },
    computed: {
        filteredData() {
            // filter the data based on the search query
            console.log('users', this.users)
            return this.users.filter(user => {
                return user.documents.firstname.toLowerCase().includes(this.searchText.toLowerCase()) || user.documents.lastname.toLowerCase().includes(this.searchText.toLowerCase()) || user.documents.email.toLowerCase().includes(this.searchText.toLowerCase());
            });
        },
        pageCount() {
            return Math.ceil(this.total / this.pageSize);
        },
        filteredUsers() {
            return this.users.filter(user => {
                return user.documents.firstname.toLowerCase().includes(this.searchText.toLowerCase()) || user.documents.lastname.toLowerCase().includes(this.searchText.toLowerCase()) || user.documents.email.toLowerCase().includes(this.searchText.toLowerCase());
            })
        },
        paginatedUsers() {
            const start = (this.currentPage - 1) * this.pageSize
            return this.filteredUsers.slice(start, start + this.pageSize)
        }
    },
    watch: {
        searchText(newValue, oldValue) {
            // update the paginated data when the search query changes
            this.currentPage = 1; // reset the current page to 1
            return this.filteredData.slice(0, this.pageSize);
        },
        currentPage(newValue, oldValue) {
            // update the paginated data when the current page changes
            const startIndex = (newValue - 1) * this.pageSize;
            return this.filteredData.slice(startIndex, startIndex + this.pageSize);
        }
    },
    methods: {
        getUsers() {
            // https://aml-verification.herokuapp.com/
            // https://kycapi.fineit.io
            // https://kycapi.aml-verify.com
            // http://localhost:3000
            axios.get('https://kycapi.aml-verify.com/getClientUsers', {
            }).then( (res) => {
                if(res.status === 200) {
                    this.users = res.data;
                    this.loading = false
                }
            }).catch((err) => {
                console.log('getClientUsers err', err)
            });
        },
        sizeChanged(size) {
            this.pageSize = size;
        }
    }
  }
</script>
  
<style scoped>
    .border-bottom{
        border-bottom: 1px solid hsl(0, 0%, 90%) !important;
    }
    .main-uploadDoc .heading-area{
        border: none;
    }
    td{
        padding: 1rem 0.5rem 0 !important;
        font-size: 12px;
        font-weight: 500;
        color: #656565;
    }
    ::v-deep .page-link {
        color: #777777; 
        border: none !important;
        background-color: #fafafa !important;
        font-size: 15px !important;
    }
    ::v-deep .page-link.active, ::v-deep .active > .page-link {
        background-color: #0796D0 !important;
        border-color: #0796D0 !important;
        color: white;
        border-radius: 4px;
    }
    ::v-deep .page-link.disabled, ::v-deep .disabled > .page-link {
        color: #d1d0d0 !important;
    }
    ::v-deep .dropdown-toggle {
        color: #777777 !important; 
        padding: 0px 15px;
    }
</style>
<style scoped>
  .centered {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 5px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0796D0 #ccc #ccc #ccc;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>