<template>
  <div>
    <modal name="s3-keys" adpative
           height="250px">
      <form style="margin-top: 20px;" action="#" @submit.prevent="updateKeys">
        <div class="form-group row">
          <label for="name" class="col-md-4 col-form-label text-md-right">Access Key</label>

          <div class="col-md-6">
            <input
                id="name"
                class="form-control"
                name="name"
                value
                required
                autocomplete="off"
                v-model="form.key"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="password" class="col-md-4 col-form-label text-md-right">Access Secret</label>

          <div class="col-md-6">
            <input
                type="password"
                class="form-control"
                name="password"
                value
                required
                autocomplete="off"
                v-model="form.secret"
            />
          </div>
        </div>
        <div class="form-group row mb-0">
          <div class="col-md-8 offset-md-4">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
          <div v-if="loadingReg" class="loader"></div>
        </div>
      </form>
    </modal>
    <dropdown-menu class="dropdown" v-model="dropdownOpen" right>
      <div class="profile dropdown-toggle">{{ name }}</div>
      <div slot="dropdown">
        <a class="dropdown-item" @click="$modal.show('s3-keys')">Update S3 Keys</a>
        <a class="dropdown-item" href="#" @click.prevent="signOut">Logout</a>
      </div>
    </dropdown-menu>
  </div>

</template>

<script>
import axios from "axios"
import {mapGetters} from "vuex";
import DropdownMenu from '@innologica/vue-dropdown-menu'
import {getAuth, signOut} from "firebase/auth";

export default {
  props: ["name"],
  name: "UserProfile",
  components: {DropdownMenu},
  data() {
    return {
      dropdownOpen: false,
      loadingReg: false,
      form: {}
    }
  },
  computed: {
    ...mapGetters({user: "user"}),
  },
  methods: {
    async updateKeys() {
      this.loadingReg = true;
      await axios.post("https://bmc-node-352801.el.r.appspot.com/s3/create-credentials", {
        clientId: this.user.data.uid,
        access_key: this.form.key,
        access_secret: this.form.secret
      })
      this.$modal.hide('s3-keys')
      this.loadingReg = false;
    },
    signOut() {
      const auth = getAuth();
      signOut(auth)
          .then(() => {
            this.$router.push({
              name: "login",
            });
          });
    },
  }
}
</script>

<style scoped>

</style>