import "firebase/auth";
import Vue from "vue";
import Router from "vue-router";
import Register from "../components/Register";
import Dashboard from "../components/Dashboard";
import Upload from "../components/Upload";
import Scan from "../components/Scan";
import History from "../components/History";
import store from "../store";
import {getAuth} from "firebase/auth";
import ClientsTable from "@/components/ClientsTable";
import AddUrl from "@/pages/AddUrl";
import UploadedDocuments from "@/pages/UploadedDocuments";
import ClientHistory from "@/pages/ClientHistory";
import EndUsers from "@/pages/EndUsers";
import EndUserBase from "@/pages/EndUserBase";


// Dashboard
import WelcomBack from '../views/WelcomBack.vue'
import DashboardDocMian from '../views/DashboardDocMian.vue'
import DashboardCamera from '../views/DashboardCamera.vue'
import DashboardTableMain from '../views/DashboardTableMain.vue'
import ProfileView from '../views/ProfileView.vue'
import DashboardScan from '../views/DashboardScan.vue'
import DashboardUploadQR from '../views/DashboardUploadQR.vue'
import DashboardViewQR from '../views/DashboardViewQR.vue'

import TermCondition from '../views/TermCondition.vue'
import PersonalInfo from '../views/PersonalInfo.vue'
import VerificationCode from '../views/VerificationCode.vue'
import DocumentInfo from '../views/DocumentInfo.vue'
import DocumentVerification from '../views/DocumentVerification.vue'
import FaceVerification from '../views/FaceVerification.vue'
import ThankYou from '../views/ThankYou.vue'
import Verified from '../views/Verified.vue'
import QRscan from '../views/QRscan.vue'
import FailedView from '../views/FailedView.vue'
import NotFound from '../views/NotFound.vue'
import ProofOfAddress from '../views/ProofOfAddress.vue'
import ProfileSuccessView from '../views/ProfileSuccessView.vue'
import Unavailable from '../views/Unavailable.vue'
import sha256 from 'crypto-js/sha256';
import axios from 'axios';

Vue.use(Router);


const router = new Router({
    mode: "history",
    // base: process.env.BASE_URL,
    routes: [
        {
            path: "*",
            redirect: "/"
        },
        {
            path: "/register",
            name: "Register",
            component: Register,
            meta: {
                requiresAdmin: true,
                requiresAuth: true,
            },
        },
        {
            path: "/dashboard",
            name: "Dashboard",
            component: Dashboard,
        },
        {
            path: "/history",
            name: "history",
            component: History,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/client-history",
            name: "client-history",
            component: ClientHistory,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/:id/documents',
            name: 'history-documents',
            component: UploadedDocuments,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/all-url',
            name: 'all-urls',
            component: AddUrl,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/clients",
            name: "clients",
            component: ClientsTable,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/upload",
            name: "upload",
            component: Upload,
            meta: {
                requiresAuth: true,
                reload: true,
            },
        },
        {
            path: "/scan",
            name: "scan",
            component: Scan,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/:id/end-users",
            name: "end-users-by-id",
            component: EndUsers,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/end-users",
            name: "end-users",
            component: EndUserBase,
            meta: {
                requiresAuth: true,
            },
        },
        // -----------------new routes-----------------
        {
            path: '/404',
            name: 'notFound',
            component: NotFound
        },
        {
            path: '/login',
            name: 'login',
            component: WelcomBack
        },
        {
            path: '/doc-main',
            name: 'doc-main',
            component: DashboardDocMian,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/dashboard-cam',
            name: 'dashboard-cam',
            component: DashboardCamera,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/profile',
            name: 'profile',
            component: ProfileView,
            meta: {
                title: 'Company Settings | BMC',
                requiresAuth: true,
            },
        },
        {
            path: '/overview',
            name: 'overview',
            component: DashboardTableMain,
            meta: {
                title: 'Clients Overview | BMC',
                requiresAuth: true,
            },
        },
        {
            path: '/dashboard-scan',
            name: 'Dashboard-Scan',
            component: DashboardScan,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/upload-qr',
            name: 'upload-qr',
            component: DashboardUploadQR,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/view-qr/:id',
            name: 'view-qr',
            component: DashboardViewQR,
            meta: {
                requiresAuth: true,
            },
        },
        
        // -----------------KYC Routes-----------------
        {
            path: '/',
            name: 'bmc',
            component: () => import('../views/BMCView.vue'),
            beforeEnter: (to, from, next) => {
                checkClientStatus().then(status => {
                    console.log('checkClientStatus router', status)
                  if (status !== 'Active') {
                   store.commit("SET_CLIENT_STATUS", false);
                    next('/unavailable');
                  } else {
                    store.commit("SET_CLIENT_STATUS", true);
                    next();
                  }
                });
            }
        },
        {
            path: '/terms',
            name : 'terms-condition',
            component: TermCondition
        },
        {
            path: '/' + sha256('personal-info'),
            name: 'personal-info',
            component: PersonalInfo,
            props: true,
            beforeEnter: (to, from, next) => {
              if (to.params.checked) {
                next();
              } else {
                next({ name: 'bmc' });
              }
            },
            meta: {
                active: true
            }
        },
        {
            path: '/' + sha256('verification-code'),
            name: 'verification-code',
            component: VerificationCode,
            props: true,
            beforeEnter: (to, from, next) => {
                console.log('to', to)
              if (to.params.phone) {
                next();
              } else {
                next({ name: 'bmc' });
              }
            },
            meta: {
                active: true
            }
        },
        {
            path: '/' + sha256('document-info'),
            name: 'document-info',
            component: DocumentInfo,
            meta: {
                verifiedUser: true,
                active: true
            }
        },
        {
            path: '/' + sha256('document-verification'),
            name: 'document-verification',
            component: DocumentVerification,
            meta: {
                verifiedUser: true,
                active: true
            }
        },
        {
            path: '/' + sha256('face-verification'),
            name: 'face-verification',
            component: FaceVerification,
            meta: {
                verifiedUser: true,
                active: true
            }
        },
        {
            path: '/' + sha256('proof-of-address'),
            name: 'proof-of-address',
            component: ProofOfAddress,
            meta: {
                verifiedUser: true,
                active: true
            }
        },
        {
            path: '/' + sha256('thankyou'),
            name : 'thankyou',
            component: ThankYou,
            meta: {
                active: true
            }
        },
        {
            path: '/' + sha256('failed'),
            name : 'failed',
            component: FailedView,
            meta: {
                active: true
            }
        },
        {
            path: '/' + sha256('verified'),
            name : 'verified',
            component: Verified,
            meta: {
                active: true
            }
        },
        {
            path: '/' + sha256('success'),
            name: 'success',
            component: ProfileSuccessView,
            meta: {
                requiresAuth: true,
                active: true
            },
        },
        {
            path: '/qr-scan',
            name : 'qr-scan',
            component: QRscan
        },
        {
            path: '/unavailable',
            name : 'unavailable',
            component: Unavailable
        }
    ],
});

router.beforeEach((to, from, next) => {
    window.document.title = to.meta && to.meta.title ? to.meta.title : 'Verify Your Identity | BMC';
    const auth = getAuth();
    const currentUser = auth.currentUser;
    console.log('currentUser', currentUser)
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
    const verifiedUser = to.matched.some((record) => record.meta.verifiedUser);
    const active = to.matched.some((record) => record.meta.active);
    if(verifiedUser) {
        console.log("verifiedUser if");
        if (store.state.verifiedUser) next();
        else {
            console.log("verifiedUser else");
            next("bmc");
        }
    }
    if (requiresAuth) {
        console.log("requiresAuth if");
        if (requiresAdmin) {
            console.log("requiresAdmin if");
            if (store.state.user.isAdmin) {
                console.log("requiresAdmin if L1");
                next();
            }
            else {
                console.log("requiresAdmin else L1");
                next("login");
            }
        } else {
            console.log("requiresAdmin else");
            if (currentUser) {
                console.log("currentUser if");
                if (to.name === "index") {
                    console.log("im here in index if");
                    // next();
                    // http://localhost:8080/?fileId=243
                    // if (to.query.fileId) next({name: "scan", query: {fileId: to.query.fileId, chain: to.query.chain}});
                    // else next("upload");
                    next("overview")
                } else {
                    console.log("im here in index else");
                    next();
                }
            } else {
                console.log("currentUser else");
                next("login");
            }
        }
    }
    else {
        console.log("requiresAuth else");
        next();
        // if (currentUser) next("overview");
        // else next();
    }
    if(active) {
        console.log('active', active);
        console.log("active if", store.state.clientStatus);
        if (store.state.clientStatus) {
            
        }
        else {
            console.log("active else", store.state.clientStatus);
            next("unavailable");
        }
    }

    // if (requiresAdmin && !store.state.user.isAdmin) next("upload");
    // else if (requiresAuth && !currentUser) next("login");
    // else if (!requiresAuth && currentUser) next("upload");
    // else {
    //   next();
    // }

    // console.log("look");
    // console.log(store.state.windowWidth);
    // console.log(store.state.sidebarHidden);

    if (store.state.windowWidth < 800 && !store.state.sidebarHidden) {
        store.commit("TOGGLE_SIDEBAR_HIDDEN");
    }
});

function checkClientStatus() {
    // Make a request to the server to check the client status
    return axios.get('https://kycapi.aml-verify.com/verifyDomain').then(response => {
      return response.data.status;
    });
  }

export default router;
