import * as firebase from "firebase/app";
const configOptions = {
    apiKey: "AIzaSyA5bxLwc5XZxyWxbP96kSmqJpsPQH28a6o",
    authDomain: "aml-verification.firebaseapp.com",
    databaseURL: "https://aml-verification-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "aml-verification",
    storageBucket: "aml-verification.appspot.com",
    messagingSenderId: "200395860084",
    appId: "1:200395860084:web:e45f09c930a935945d4921",
    measurementId: "G-P8PJQ9DFVR"
};

const app = firebase.initializeApp(configOptions);

export default app;