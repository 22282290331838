<template>
    <div class="cam-main-dashboard">

        <!-- main body tag -->
        <div class="body-div">
            <div class="main-uploadDoc">
            <!---------------- header Start----------------->
                <div class="dashboard-header">
                    <div class="heading-area">
                        <HeadDropdown/>
                        <h2>Upload Document</h2>
                    </div>
                </div>
                <!---------------- header End----------------->

                <!-- Inner body tag -->
                <div class="">
                    <div class="dashbord-tabs">
                        <b-tabs content-class="mt-3" ref="tabs">
                            <b-tab title="Select a blockchain network" disabled>  
                            </b-tab>
                            <b-tab title="Solana" active>
                                <div class="dash-tab-inner">
                                    <div class="tab-gray-div">
                                        <div class="tabs-btn">
                                            <div role="button">
                                                <v-file-input
                                                    v-model="theFile"
                                                    @change="fileInputOnChangeHandler"
                                                    :disabled="loading_hash"
                                                    style="width: 60%; margin-left: 20%; display: none"
                                                    label="Insert file"
                                                    ref="file"
                                                ></v-file-input>
                                                <button class="main-btn"  @click="BrowseFile" :disabled="loading_hash">
                                                    Browse file
                                                </button>
                                                <span v-if="!theFile.name">No file selected</span>
                                                <span v-else>{{ theFile.name }}</span>
                                            </div>
                                            <div>
                                                <!-- <router-link to="/upload-qr">
                                                    <button class="gray-light-btn">
                                                        Create BMC QR Code
                                                    </button>
                                                </router-link> -->
                                                <b-button variant="secondary" :disabled="!inputFile || loading_hash" @click="getHashSum" :loading="loading_hash">
                                                    Create BMC QR Code
                                                    <b-spinner small v-if="loading_hash"></b-spinner>
                                                </b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </b-tab>
                            <b-tab title="Ethereum">
                                <div class="dash-tab-inner">
                                    <div class="tab-gray-div">
                                        <div class="tabs-btn">
                                            <div>
                                                <v-file-input
                                                    v-model="theFile"
                                                    @change="fileInputOnChangeHandler"
                                                    :disabled="loading_hash"
                                                    style="width: 60%; margin-left: 20%; display: none"
                                                    label="Insert file"
                                                    ref="file"
                                                ></v-file-input>
                                                <button class="main-btn"  @click="BrowseFile" :disabled="loading_hash">
                                                    Browse file
                                                </button>
                                                <span v-if="!theFile.name">No file selected</span>
                                                <span v-else>{{ theFile.name }}</span>
                                            </div>
                                            <div>
                                                <b-button variant="secondary" :disabled="!inputFile || loading_hash" @click="getHashSum" :loading="loading_hash">
                                                    Create BMC QR Code
                                                    <b-spinner small v-if="loading_hash"></b-spinner>
                                                </b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>

                <!-- Upload Doc Bottom -->
                <div class="dashboard-bottom">
                    <div class="tabs-btn">
                        <div>
                            <router-link to="dashboard-cam">
                                <button class="light-btn-blue">
                                    <span>
                                        <img src="../../assets/images/qr-code-blue-icon.svg" alt="icon">
                                    </span>
                                    Scan BMC QR Code
                                </button>
                            </router-link>
                        </div>
                        <div>
                            <a href="/doc-main" >
                                <button class="textblue-btn">
                                    Upload another file
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
  
<script>
    import HeadDropdown from '../new-components/headDropdown.vue';
    import {providers} from "ethers";
    import {sha256, toUtf8Bytes} from "ethers/lib/utils";
    const reader = new FileReader();
    export default {
        name: 'UploadDoc',
        components: {
            HeadDropdown
        },
        data() {
            return {
                loading_hash: false,
                theFile: [],
                inputFile: File | null, // @change
                SaveType: "",
                query_fileId: null,
                file_downloadURL: null,
                file_hashSum: null,
                qrImg: null,
                file_uploadedAt: null,
                qr_downloadURL: null,
                file_Id: null,
            }
        },
        methods: {
            BrowseFile() {
                this.$refs.file.$refs.input.click();
            },
            async fileInputOnChangeHandler(File) {
                if (File) {
                    this.inputFile = File;
                    this.SaveType = this.inputFile.type;
                    // console.log(this.SaveType);
                    // console.log({ theFile: this.theFile });
                    // console.log({ File: File });
                    reader.readAsBinaryString(File);
                }
            },
            async getHashSum() {
                this.loading_hash = true;
                this.query_fileId = this.qrImg = this.file_downloadURL = this.file_hashSum = this.file_uploadedAt = this.file_Id = null;
                console.log('tabIndex', this.$refs.tabs.currentTab)
                if (reader.result) {
                    this.fileContent = reader.result;
                    console.log({content: this.fileContent});
                    // console.log({ bytes: toUtf8Bytes(this.fileContent) });
                    const hashSum = sha256(toUtf8Bytes(this.fileContent));
                    //console.log({ hashSum });
                    console.log("net=", this.currentNetwork)
                    if (this.$refs.tabs.currentTab === 2) {
                        const {fileId, fileTimestamp} = await this.addToContract(hashSum);
                        this.file_Id = `${fileId}`;
                        this.fileTimestamp = fileTimestamp;
                        this.chain = "eth"
                        this.qrImg = await QRCode.toDataURL(`https://bmc-qr.web.app/?fileId=${fileId}&chain=${this.chain}`);
                    } else {
                        const {fileId, fileTimestamp} = await this.addToSolana(hashSum);
                        this.file_Id = `${fileId}`;
                        this.fileTimestamp = fileTimestamp;
                        this.chain = "sol"
                        this.qrImg = await QRCode.toDataURL(`https://bmc-qr.web.app/?fileId=${fileId}&chain=${this.chain}`);
                    }
                    //console.log({ qrImg });
                    this.file_downloadURL = await this.addToStorage(this.file_Id, this.inputFile);

                    //this.qrImg = qrImg;
                    const newImg = this.b64toBlob(this.qrImg);
                    console.log({newImg});
                    this.qr_downloadURL = await this.addQrToStorage(this.file_Id, newImg);
                    console.log(this.qr_downloadURL);
                    this.file_hashSum = hashSum;
                    this.file_uploadedAt = new Date(this.fileTimestamp * 1000).toLocaleString();
                    //this.file_Id = `${fileId}`;
                    // console.log("net = ", this.currentNetwork)
                    const mes = await this.addToFire(
                        // this.user.data.email,
                        this.file_hashSum,
                        this.file_uploadedAt,
                        this.inputFile.name,
                        Number(this.file_Id),
                        this.inputFile.size,
                        this.file_downloadURL,
                        this.qr_downloadURL,
                        this.currentNetwork
                    );
                    console.log({mes});
                }

                this.loading_hash = false;
            },
            b64toBlob(dataURI) {
                var byteString = atob(dataURI.split(",")[1]);
                var ab = new ArrayBuffer(byteString.length);
                var ia = new Uint8Array(ab);

                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                return new Blob([ab], {type: "image/jpeg"});
            },
            async addToFire(FileHash, hash_time, fileName, fileId, fileSize, DownloadLink, QrCodeLink, chain) {
            console.log({chain})
            try {
                let mes = "";
                const functions = getFunctions(firebaseApp);
                const addToFirestore = httpsCallable(functions, 'addToFirestore');
                await addToFirestore({
                FileHash: FileHash,
                hash_time: hash_time,
                fileName: fileName,
                fileId: fileId,
                fileSize: fileSize,
                DownloadLink: DownloadLink,
                QrCodeLink: QrCodeLink,
                SaveType: this.SaveType,
                chain: chain
                }).then(result => {
                mes = result.data.text;
                });
                return mes;
            } catch (err) {
                console.error("Error adding document: ", err);
            }
            },
            async addToSolana(hashSum) {
                let fileId,
                fileTimestamp = null;
                const functions = getFunctions(firebaseApp);
                const addHashToSol = httpsCallable(functions, 'addHashToSol');
                await addHashToSol({file_hashSum: hashSum}).then(result => {
                    const data = result.data;
                    fileId = data.id;
                });
                //console.log({fileId})
                const getSolInfo = httpsCallable(functions, 'getSolInfo');
                await getSolInfo({fileId: fileId}).then(result => {
                    const data = result.data;
                    fileTimestamp = data.timestamp;
                });
                //console.log({fileTimestamp})
                return {fileId, fileTimestamp};
            },
            async addToContract(hashSum) {
            let fileId,
                fileTimestamp = null;
            const functions = getFunctions(firebaseApp);
            const addHash = httpsCallable(functions, 'addHash');
            await addHash({file_hashSum: hashSum}).then(result => {
                const data = result.data;
                fileId = data.id;
                fileTimestamp = data.stamp;
            });
            return {fileId, fileTimestamp};
            },
            async addToStorage(id, file) {
            let chain = this.currentNetwork
            const storage = getStorage(firebaseApp);
            const fRef = ref(storage, `files/${chain}/${id}/${file.name}`);
            // console.log({ fRef });

            await uploadBytes(fRef, file);
            // console.log({ fSnap });
            // console.log({ fUrl });
            return await getDownloadURL(fRef)
            },
            async addQrToStorage(id, qr) {
            const name = await this.getQRcodeName(this.theFile.name);
            let chain = this.currentNetwork
            const storage = getStorage(firebaseApp);
            const fRef = ref(storage, `qrCodes/${chain}/${id}/${name}`);

            await uploadBytes(fRef, qr).then(() => {
                console.log('Uploaded qr code');
            });

            return await getDownloadURL(fRef);
            },
            async getDownloadLink(fileId, fileName) {
            const storage = getStorage(firebaseApp);
            let chain = this.currentNetwork;
            let fRef;
            if (!fileName) {
                const folderRef = ref(storage, `files/${chain}/${fileId}/`);
                const fList = await listAll(folderRef);
                if (!fList.items.length) {
                throw new Error("No file with such Id");
                } else
                fRef = ref(storage, fList.items[0]);
            } else {
                fRef = ref(storage, `files/${chain}/${fileId}/${fileName}`);
            }
            // console.log({ fRef });
            // console.log({ fUrl });
            return await getDownloadURL(fRef);
            },
        }
    }
</script>
  
<style scoped>
    .main-uploadDoc .heading-area{
        border: none;
    }
</style>