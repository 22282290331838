<template>
  <div v-if="user.loggedIn" ref="uploadmain" class="upload-main">
    <div class="upload-header">
      <v-row>
        <v-col cols="12" md="6">
          <div class="header">
            <h2>Upload File</h2>
            <div class="small-text">Save photos or documents to selected blockchain</div>
          </div>
        </v-col>
        <v-col cols="12" md="6" :style="qrImg ? 'display: none' : ''">
          <div data-app class="select-network-wrapper">
            <div>Selected blockchain:</div>
            <div v-if="!$store.state.user.isAdmin">Solana</div>
            <!-- <v-select :items="networks" v-model="currentNetwork" solo></v-select> -->
            <v-select v-else v-model="currentNetwork" :items="networks" dense solo>
              <template #item="{ attrs, on, item }">
                <v-list-item v-bind="attrs" v-on="on">
                  <div class="network-list">
                    <img :src="item.icon"/>
                  </div>
                  {{ item.text }}
                </v-list-item>
              </template>
            </v-select>
          </div>
        </v-col>
      </v-row>
      <user-profile :name="user.data.displayName"></user-profile>
    </div>
    <div class="uploadFile" :class="ifMDandUp ? 'uploadFile-row' : 'uploadFile-column'"
         :style="!ifMDandUp && qrImg ? 'display: none' : ''">
      <div class="left">
        <!-- <input type="file" ref="file" style="display: none" /> -->
        <v-file-input
            v-model="theFile"
            @change="fileInputOnChangeHandler"
            :disabled="loading_hash"
            style="width: 60%; margin-left: 20%; display: none"
            label="Insert file"
            ref="file"
        ></v-file-input>
        <button @click="BrowseFile" :disabled="loading_hash" class="browse-button">
          Browse File
        </button>
      </div>
      <div :class="ifMDandUp ? 'center' : 'mobile-center'">
        <div class="selected-file" v-if="!theFile.name">No file selected</div>
        <div class="selected-file" v-else>{{ shortenedName(theFile.name) }}</div>
      </div>
      <div class="right">
        <button :disabled="!inputFile || loading_hash" @click="getHashSum" :loading="loading_hash"
                class="create-button">
          <!-- <i class="fa fa-spinner fa-spin"></i> -->
          Create BMC QR Code
        </button>
      </div>
    </div>
    <div v-if="qrImg" class="div-success">
      <div class="alert-success">
        <div class="d-flex" style="justify-content: flex-start; align-items: center">
          <img src="../static/success.png" style="width: 18px; heigth: 18px"/>
          <div style="padding-left: 27px"></div>
          <span class="bold-text">Success!</span
          ><span :style="ifMDandUp ? '' : 'display: none'">Your BMC QR code has been successfully generated and saved to blockchain.</span>
        </div>
        <button>
          <img src="../static/close_alert.png" style="width: 24px; heigth: 24px"/>
        </button>
      </div>
      <div :style="ifMDandUp ? '' : 'display: none'">
        <div style="padding-top: 35px"><h3>Result</h3></div>
        <hr/>
      </div>
      <hr/>
      <!-- <div :class="ifMDandUp ? 'div-result' : 'div-result-mobile'" v-if="qrImg"> -->
      <div :class="ifMDandUp ? 'div-result' : 'div-result-mobile'" v-if="qrImg">
        <div class="qrcode" style="height: 194px !important;" :style="ifMDandUp ? '' : 'padding: 0'">
          <a :href="qrImg" :download="getQRcodeName(theFile.name)">
            <img :src="qrImg" :alt="'download ' + getQRcodeName(theFile.name)" style=""/>
          </a>
        </div>
        <div class="qrcodeinfo" style="height: 194px !important" :style="ifMDandUp ? '' : 'padding: 0'">
          <div class="info-line flex-wrap">
            <div class="keys" v-if="file_Id">ID</div>
            <div class="values" v-if="file_Id">{{ file_Id }}</div>
          </div>
          <div class="info-line flex-wrap">
            <div class="keys">File</div>
            <div class="values">{{ shortenedName(theFile.name) }}</div>
          </div>
          <div class="info-line flex-wrap">
            <div class="keys" v-if="file_hashSum">Hash</div>
            <div class="values" v-if="file_hashSum">
              {{ file_hashSum }}
            </div>
          </div>
          <div class="info-line flex-wrap">
            <div class="keys" v-if="file_uploadedAt">Upload Date</div>
            <div class="values" v-if="file_uploadedAt">
              {{ file_uploadedAt }}
            </div>
          </div>
          <div class="info-line flex-wrap">
            <div class="keys" v-if="file_uploadedAt">Blockchain</div>
            <div class="values" v-if="file_uploadedAt">
              {{ currentNetwork }}
            </div>
          </div>
          <div class="info-line flex-wrap">
            <div class="keys">Download</div>
            <div class="values">
              <a :href="qrImg" :download="getQRcodeName(theFile.name)">
                <button class="downoload-button" :href="qrImg" @click="getQRcodeName(theFile.name)"
                        :download="getQRcodeName(theFile.name)">
                  Download QR
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="height: 100%" class="QRCodeInfo">
      <div v-if="loading_hash" class="loader"></div>
      <div v-else class="upload-text">Upload a file to generate a QR code</div>
    </div>
    <hr/>
    <div :class="ifMDandUp ? 'footer' : 'footer-mobile'">
      <div>
        <router-link to="Scan">
          <button class="scan-button">Scan BMC QR Code</button>
        </router-link>
      </div>
      <div>
        <button class="upload-another-button" onClick="window.location.reload();"
                :style="ifMDandUp ? '' : 'margin-top: 15px'">
          Upload another file
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
button:active {
  background-color: #263853;
}

.upload-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  width: 100%;
  background: #ffffff;
  padding: 50px 70px;
  @media only screen and (max-width: 799px) {
    padding: 20px 30px;
  }
}

.upload-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 13px;
}

.upload-header .select-network-wrapper {
  max-width: 200px;
}

.upload-header .small-text {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #808080;
}

.upload-header h2 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
}

.upload-header .profile {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
}

.uploadFile {
  background: #f9f9f9;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 13px;
  margin-bottom: 20px;
}

.uploadFile-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
}

.uploadFile-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
}

.uploadFile .left,
.right {
}

.uploadFile .center {
  width: 100%;
}

.uploadFile .mobile-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.uploadFile .browse-button {
  background: #0796d0;
  border: 1px solid #f0f0f0;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  color: #ffffff;
  border-radius: 5px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 200px;
  height: 40px;
}

.create-button {
  background: #0796d0;
  border: 1px solid #f0f0f0;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  color: #ffffff;
  border-radius: 5px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 200px;
  height: 50px;
}

.uploadFile .create-button:disabled {
  background-color: rgba(38, 90, 112, 0.49);
}

.uploadFile .selected-file {
  font-family: "DM Sans", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #656565;
  padding: 0 32px;
}

.QRCodeInfo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.QRCodeInfo .upload-text {
  font-family: "DM Sans", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: rgba(101, 101, 101, 0.5);
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.scan-button {
  width: 220px;
  height: 52px;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #0796d0;
  background: rgba(63, 209, 255, 0.07);
  border: 1px solid #0796d0;
  box-sizing: border-box;
  border-radius: 10px;
}

.upload-another-button {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #0796d0;
}

.alert-success {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid rgba(243, 250, 248, 1);
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #2f7669;

  .bold-text {
    font-weight: 700;
  }
}

.div-success {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-grow: 1;
  //height: 100%;
  h3 {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 13px;
    color: #000000;
  }

  hr {
    margin: 0;
  }

  .div-result-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    box-sizing: border-box;
    height: 100%;
  }

  .div-result {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    box-sizing: border-box;
    height: 100%;
  }

  .qrcode {
    padding-right: 43px;
  }

  .qrcodeinfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 0 8px 43px;
    box-sizing: border-box;
    //height: 100% !important;
    width: 100%;

    .info-line {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .keys {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "DM Sans", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #a0a0a0;
      width: 100px !important;
    }

    .values {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "DM Sans", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      word-break: break-all;
      color: #3d3d3d;

      .downoload-button {
        background: #0796d0;
        width: 200px;
        height: 30px;
        border: 1px solid #f0f0f0;
        backdrop-filter: blur(4px);
        color: white;
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 5px;
      }
    }
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable */
import {providers} from "ethers";
import {sha256, toUtf8Bytes} from "ethers/lib/utils";
import QRCode from "qrcode";
import "firebase/storage";
import "firebase/functions";
import "firebase/firestore";
import {mapGetters} from "vuex";
import {getStorage, ref, uploadBytes, getDownloadURL, listAll} from "firebase/storage";
import firebaseApp from "@/firebase";
import {getFunctions, httpsCallable} from "firebase/functions";

// import hashStorage from "../../abis/Unitum.json";

// const infuraKey = "";
// const _providerE = new providers.InfuraProvider(4, infuraKey);
// const contractAddress = "";
// const STOR = new Contract(contractAddress, hashStorage, _providerE);
const reader = new FileReader();
import DropdownMenu from '@innologica/vue-dropdown-menu'
import UserProfile from "@/components/UserProfile";

export default {
  components: {UserProfile, DropdownMenu},
  data() {
    return {
      dropdownOpen: false,
      errorMessage_misc: "",
      provider: providers.Web3Provider | null,
      signer: providers.JsonRpcSigner | null,
      wallet: "",
      warningMessage: "",
      theFile: [], // v-model
      inputFile: File | null, // @change
      fileContent: null, // reader.re
      qrImg: null,
      qrSrc: null,
      loading_provider: false,
      loading_hash: false,
      loading_fileInfo: false,
      chainId: null,
      dialog: false,
      query_fileId: null,
      file_downloadURL: null,
      file_hashSum: null,
      file_uploadedAt: null,
      qr_downloadURL: null,
      file_Id: null,
      fileTimestamp: null,
      hashes:
          {
            txHashCollect: "",
            txHashDispense: ""
          } | null,
      uploadMainWidth: window.screen.width,
      SaveType: "",
      currentNetwork: "Solana",
      networks: [
        {icon: '', text: 'Ethereum', value: 'Ethereum'},
        {icon: '', text: 'Solana', value: 'Solana'},
      ],
      languageSelectorValue: 'en',
      chain: "",
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    }),
    err_chainId() {
      return !!this.chainId && this.chainId !== 97;
    },
    errorMessage() {
      return this.err_chainId ? `You selected wrong network for this direction. Select Rinkeby Testnet in Metamask` : this.errorMessage_misc;
    },
    ifMDandUp() {
      if (this.uploadMainWidth > 799) return true;
      return false;
    }
    // connectButtonLabel() {
    //   return this.wallet ? this.wallet.substr(0, 6) + "..." + this.wallet.substr(-4) : "Connect Wallet";
    // },
  },
  async mounted() {
    this.query_fileId = this.getQueryStringValue("fileId") || null;
    const query_fileName = this.getQueryStringValue("fileName") || undefined;
    // console.log({ query_fileId: this.query_fileId });
    // console.log({ query_fileName });
    this.onResize();
    if (this.query_fileId) {
      const downloadURL = await this.getDownloadLink(this.query_fileId, query_fileName);
      const qrImg = await QRCode.toDataURL(`https://bmc-qr.web.app/?fileId=${this.query_fileId}`);
      //var docInfoById = firebase.functions().httpsCallable("docInfoById");
      let hashsum,
          timestamp = null;
      const functions = getFunctions(firebaseApp);
      const docInfoById = httpsCallable(functions, 'docInfoById');
      await docInfoById({fileId: this.query_fileId}).then(result => {
        const data = result.data;
        hashsum = data.hash;
        timestamp = data.stamp;
      });
      //const { hashsum, timestamp } = await STOR.docInfoById(this.query_fileId);
      this.file_downloadURL = downloadURL;
      this.qrImg = qrImg;
      this.file_hashSum = hashsum;
      this.file_uploadedAt = new Date(timestamp.toNumber() * 1000).toLocaleString();
      this.file_Id = this.query_fileId;
    }
  },
  watch: {
    uploadMainWidth(newWidth, oldWidth) {
      // console.log(this.ifMDandUp);
    },
    "$store.state.sidebarHidden"() {
      this.onResize();
    },
    "$store.state.windowWidth"() {
      this.onResize();
    }
  },
  methods: {
    BrowseFile() {
      this.$refs.file.$refs.input.click();
    },
    Out() {
      this.$router.replace({
        name: "Dashboard"
      });
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    getQueryStringValue(key) {
      return decodeURIComponent(
          window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1")
      );
    },
    // async updateChainId() {
    //   this.chainId = (await this.provider?.getNetwork())?.chainId || null;
    // },
    async getHashSum() {
      this.loading_hash = true;
      this.query_fileId = this.qrImg = this.file_downloadURL = this.file_hashSum = this.file_uploadedAt = this.file_Id = null;
      if (reader.result) {
        this.fileContent = reader.result;
        console.log({content: this.fileContent});
        // console.log({ bytes: toUtf8Bytes(this.fileContent) });
        const hashSum = sha256(toUtf8Bytes(this.fileContent));
        //console.log({ hashSum });
        console.log("net=", this.currentNetwork)
        if (this.currentNetwork === "Ethereum") {
          const {fileId, fileTimestamp} = await this.addToContract(hashSum);
          this.file_Id = `${fileId}`;
          this.fileTimestamp = fileTimestamp;
          this.chain = "eth"
          this.qrImg = await QRCode.toDataURL(`https://bmc-qr.web.app/?fileId=${fileId}&chain=${this.chain}`);
        } else if (this.currentNetwork === "Solana") {
          const {fileId, fileTimestamp} = await this.addToSolana(hashSum);
          this.file_Id = `${fileId}`;
          this.fileTimestamp = fileTimestamp;
          this.chain = "sol"
          this.qrImg = await QRCode.toDataURL(`https://bmc-qr.web.app/?fileId=${fileId}&chain=${this.chain}`);
        } else {
          throw new Error("No chain selected")
        }
        //console.log({ qrImg });
        this.file_downloadURL = await this.addToStorage(this.file_Id, this.inputFile);

        //this.qrImg = qrImg;
        const newImg = this.b64toBlob(this.qrImg);
        console.log({newImg});
        this.qr_downloadURL = await this.addQrToStorage(this.file_Id, newImg);
        console.log(this.qr_downloadURL);
        this.file_hashSum = hashSum;
        this.file_uploadedAt = new Date(this.fileTimestamp * 1000).toLocaleString();
        //this.file_Id = `${fileId}`;
        // console.log("net = ", this.currentNetwork)
        const mes = await this.addToFire(
            // this.user.data.email,
            this.file_hashSum,
            this.file_uploadedAt,
            this.inputFile.name,
            Number(this.file_Id),
            this.inputFile.size,
            this.file_downloadURL,
            this.qr_downloadURL,
            this.currentNetwork
        );
        console.log({mes});
      }

      this.loading_hash = false;
    },
    b64toBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], {type: "image/jpeg"});
    },
    async addToFire(FileHash, hash_time, fileName, fileId, fileSize, DownloadLink, QrCodeLink, chain) {
      console.log({chain})
      try {
        let mes = "";
        const functions = getFunctions(firebaseApp);
        const addToFirestore = httpsCallable(functions, 'addToFirestore');
        await addToFirestore({
          FileHash: FileHash,
          hash_time: hash_time,
          fileName: fileName,
          fileId: fileId,
          fileSize: fileSize,
          DownloadLink: DownloadLink,
          QrCodeLink: QrCodeLink,
          SaveType: this.SaveType,
          chain: chain
        }).then(result => {
          mes = result.data.text;
        });
        return mes;
      } catch (err) {
        console.error("Error adding document: ", err);
      }
    },
    async requestSwap() {
      try {
        const functions = getFunctions(firebaseApp);
        const addHash = httpsCallable(functions, 'addHash');
        addHash({file_hashSum: this.file_hashSum}).then(result => {
          // console.log(result);
        });
        //console.log(this.hashes)
      } catch (error) {
        console.error({...error});
        throw new Error(error.response.data);
      }
      //await this.loadContractInfo()
    },
    async handleClickRequest() {
      this.loading_request = true;
      try {
        this.warningMessage = "";
        this.hashes = null;
        await this.requestSwap();
      } catch (error) {
        this.warningMessage = error.message;
        console.error(error);
      }
      //this.loading_request = false
    },
    async addToSolana(hashSum) {
      let fileId,
          fileTimestamp = null;
      const functions = getFunctions(firebaseApp);
      const addHashToSol = httpsCallable(functions, 'addHashToSol');
      await addHashToSol({file_hashSum: hashSum}).then(result => {
        const data = result.data;
        fileId = data.id;
      });
      //console.log({fileId})
      const getSolInfo = httpsCallable(functions, 'getSolInfo');
      await getSolInfo({fileId: fileId}).then(result => {
        const data = result.data;
        fileTimestamp = data.timestamp;
      });
      //console.log({fileTimestamp})
      return {fileId, fileTimestamp};
    },
    async addToContract(hashSum) {
      let fileId,
          fileTimestamp = null;
      const functions = getFunctions(firebaseApp);
      const addHash = httpsCallable(functions, 'addHash');
      await addHash({file_hashSum: hashSum}).then(result => {
        const data = result.data;
        fileId = data.id;
        fileTimestamp = data.stamp;
      });
      return {fileId, fileTimestamp};
    },
    async addToStorage(id, file) {
      let chain = this.currentNetwork
      const storage = getStorage(firebaseApp);
      const fRef = ref(storage, `files/${chain}/${id}/${file.name}`);
      // console.log({ fRef });

      await uploadBytes(fRef, file);
      // console.log({ fSnap });
      // console.log({ fUrl });
      return await getDownloadURL(fRef)
    }
    ,
    async addQrToStorage(id, qr) {
      const name = await this.getQRcodeName(this.theFile.name);
      let chain = this.currentNetwork
      const storage = getStorage(firebaseApp);
      const fRef = ref(storage, `qrCodes/${chain}/${id}/${name}`);

      await uploadBytes(fRef, qr).then(() => {
        console.log('Uploaded qr code');
      });

      return await getDownloadURL(fRef);
    }
    ,
    async getDownloadLink(fileId, fileName) {
      const storage = getStorage(firebaseApp);
      let chain = this.currentNetwork;
      let fRef;
      if (!fileName) {
        const folderRef = ref(storage, `files/${chain}/${fileId}/`);
        const fList = await listAll(folderRef);
        if (!fList.items.length) {
          throw new Error("No file with such Id");
        } else
          fRef = ref(storage, fList.items[0]);
      } else {
        fRef = ref(storage, `files/${chain}/${fileId}/${fileName}`);
      }
      // console.log({ fRef });
      // console.log({ fUrl });
      return await getDownloadURL(fRef);
    },
    async fileInputOnChangeHandler(File) {
      if (File) {
        this.inputFile = File;
        this.SaveType = this.inputFile.type;
        // console.log(this.SaveType);
        // console.log({ theFile: this.theFile });
        // console.log({ File: File });
        reader.readAsBinaryString(File);
      }
    },
    // async clickConnectMetamask() {
    //   await this.connectMetamask();
    //   (window.ethereum).on("chainChanged", async (chainId) => {
    //     console.log({ chainId });
    //     await this.connectMetamask();
    //   });
    // },
    // async connectMetamask() {
    //   this.loading_provider = true;
    //   try {
    //     if (!window.ethereum) throw new Error("Please set up MetaMask properly");
    //     await (window.ethereum).request({method: 'eth_requestAccounts'});
    //     this.provider = new providers.Web3Provider((window).ethereum);
    //     this.signer = this.provider.getSigner();
    //     this.wallet = await this.signer.getAddress();
    //     await this.updateChainId();
    //   } catch (error) {
    //     this.errorMessage_misc = "Could not connect MetaMask. Error: " + error.message;
    //     console.error(error);
    //   }
    //   this.loading_provider = false;
    //   this.dialog = false;
    // },
    getQRcodeName(s) {
      if (s === "") return s;
      for (let i = s.length - 1; i >= 0; i--) {
        if (s[i] === ".") return "QR_" + s.slice(0, i) + ".png";
      }
      return s;
    },
    onResize() {
      this.uploadMainWidth = this.$refs.uploadmain.clientWidth;
    },
    getFileNameAndType(s) {
      let name = "";
      let type = "";
      if (s === "") return s;
      for (let i = s.length - 1; i >= 0; i--) {
        if (s[i] === ".") {
          name = s.slice(0, i);
          type = s.slice(i + 1, s.length);
          return {name, type};
        }
      }
      name = s;
      return {name, type};
    },
    shortenedName(s) {
      let str = this.getFileNameAndType(s);
      if (this.ifMDandUp) {
        if (s.length > 20) {
          if (str.type == "") return str.name.slice(0, 5) + "..." + str.name.slice(-6, -1);
          else return str.name.slice(0, 5) + "..." + str.name.slice(-6, -1) + "." + str.type;
        }
      } else {
        if (s.length > 10) {
          if (str.type == "") return str.name.slice(0, 3) + "..." + str.name.slice(-3, -1);
          else return str.name.slice(0, 3) + "..." + str.name.slice(-3, -1) + "." + str.type;
        }
      }
      return s;
    }
  }
};
</script>
