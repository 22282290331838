<template>
  <div>
    <NavBarVue/>
    <template>
      <div class="main-body-div">
          <div class="idnetity-main">
            <h3 class="pb-2 text-center">Sorry</h3>
            <p class="font-12 text-center">We are unable to find this Route</p>
            <p class="font-12 text-center">Try to contact support</p>
          </div>
      </div>
    </template>
    <FooterCompVue/>
  </div>
</template>


<script>
import NavBarVue from '../components/new-components/NavBar.vue';
import FooterCompVue from '../components/new-components/FooterComp.vue';

export default {
  
  name: 'NotFound',
  components: {
    NavBarVue,
    FooterCompVue
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
  .centered {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 5px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0796D0 #ccc #ccc #ccc;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>