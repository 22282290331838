<template>
    <div class="cam-main-dashboard">

        <!-- main body tag -->
        <div class="body-div">
            <div class="main-uploadDoc">

                <!---------------- header Start----------------->
                <div class="dashboard-header">
                    <div class="heading-area">
                        <HeadDropdown/>
                        <h2>Client Registration</h2>
                    </div>
                </div>
                <!---------------- header End----------------->
                <div class="pt-4">
                    <div class="main-body-div">
                        <div class="navbar-main">
                            <b-container class="pt-4 centered">
                                <div class="d-flex justify-content-center align-items-center">
                                <img src="../../assets/images/radioTick.svg">
                                </div>
                                <h3 class="pt-4 pb-2 text-center">Profile Updated Successfully</h3>
                                <div class="btn-div pt-4">
                                    <button class="main-btn" role="button" @click.prevent="goToClients">
                                        Go to Client Overview
                                    </button>
                                </div>
                            </b-container>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>

</template>
  
  
<script>
  import HeadDropdown from '../new-components/headDropdown.vue';

  export default {

    name: 'Success',
    components: {
        HeadDropdown
    },
    methods: {
        goToClients() {
            this.$router.push({ name: "overview" }); 
        }
    },
  }
</script>
  
<style scoped>
  .input-label {
    display: flex;
    font-weight: 600;
    font-size: 14px;
  }
  .btn-div{
    justify-content: space-around;
    flex-direction: unset !important;
  }
  .form-select {
    background-color: #f6f4f2 !important;
  }
</style>