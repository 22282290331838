<template>
  <div class="upload-main" ref="clientTable">
    <div class="historytop">
      <modal name="client-register" adpative
             height="400px">
        <form style="margin-top: 20px;" action="#" @submit.prevent="registerClient">
          <div class="form-group row">
            <label for="name" class="col-md-4 col-form-label text-md-right">Name</label>

            <div class="col-md-6">
              <input
                  id="name"
                  class="form-control"
                  name="name"
                  value
                  required
                  autocomplete="off"
                  v-model="form.name"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="email" class="col-md-4 col-form-label text-md-right">Email</label>

            <div class="col-md-6">
              <input
                  id="email"
                  type="email"
                  class="form-control"
                  name="email"
                  value
                  required
                  autocomplete="off"
                  v-model="form.email"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

            <div class="col-md-6">
              <input
                  id="password"
                  type="password"
                  class="form-control"
                  name="password"
                  autocomplete="off"
                  required
                  v-model="form.password"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="domain" class="col-md-4 col-form-label text-md-right">Domain</label>

            <div class="col-md-6">
              <input
                  id="password"
                  type="url"
                  class="form-control"
                  name="domain"
                  autocomplete="off"
                  placeholder="https://www.example.com"
                  required
                  v-model="form.domain"
              />
            </div>
          </div>
          <div class="form-group row mb-0">
            <div class="col-md-8 offset-md-4">
              <button type="submit" class="btn btn-primary">Register client</button>
            </div>
            <div v-if="loadingReg" class="loader"></div>
          </div>
        </form>
      </modal>

      <div class="upload-header">
        <div class="header" style="display: flex;">
          <h2>All Clients</h2>
          <button @click="$modal.show('client-register')" style="margin-left: 30px;background: #0796d0;
          border: 1px solid #f0f0f0;
          -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
          color: #ffffff;
          border-radius: 5px;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0;
          text-align: center;
          width: 110px;
          height: 40px;">Add Client
          </button>
        </div>
        <div class="profile">{{ user.data.displayName }}</div>
      </div>
      <div class="search">
        <input v-model="searchFile" class="search-field" type="search" placeholder="Search..."/>
        <img src="../static/search.png" class="search-icon"/>
      </div>
      <div
          v-if="!items.length && !itemsLengthIsZero"
          style="
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div class="loader"></div>
      </div>

      <div class="history-mobile" v-else-if="!ifMDandUp" style="width: 100%; max-height: 100%">
        <div
            class="tableheader"
            style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <div style="flex: 0.9">Name</div>
          <div style="flex: 0.1">Email</div>
        </div>
        <hr/>
        <div class="tablebody" style="width: 100%; max-height: 100%; overflow-y: auto !important">
          <div v-for="(item, i) in chunkedItems" :key="i">
            <div
                style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
              "
            >
              <div class="table-element" style="flex: 0.9">
                {{ item.displayName }}
              </div>
              <div class="table-element" style="flex: 0.1">
                {{ item.email }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="history" style="width: 100%; max-height: 100%">
        <div
            class="tableheader"
            style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <div style="flex: 0.05">SI No.</div>
          <div style="flex: 0.1">Name</div>
          <div style="flex: 0.2">Email</div>
          <div style="flex: 0.05">Users</div>
          <div style="flex: 0.1">Transactions</div>
          <div style="flex: 0.1">Aws Status</div>
          <div style="flex: 0.1">Created At</div>
          <div style="flex: 0.2">Actions</div>
        </div>
        <hr/>
        <div class="tablebody" style="width: 100%; max-height: 100%; overflow-y: scroll !important">
          <div v-for="(item, i) in chunkedItems" :key="i">
            <div
                style="
                        display: flex;
                        align-items: left;
                        width: 100%;
                        "
            >
              <div class="table-element" style="flex: 0.05;width:30%;">
                {{ i + 1 }}
              </div>
              <div class="table-element" style="flex: 0.1;width:10%;">
                {{
                  item.displayName
                }}
              </div>
              <div class="table-element" style="flex: 0.2">
                {{ item.email }}
              </div>
              <div class="table-element" style="flex: 0.05">
                {{ item.transaction.length }}
              </div>
              <div class="table-element" style="flex: 0.1">
                {{ getTransactionCount(item.transaction) }}
              </div>
              <div class="table-element" style="flex: 0.1">
                {{ item.aws_status ? "Updated" : "Not Updated" }}
              </div>
              <div class="table-element" style="flex: 0.1">
                {{ formatDate(item.creationTime) }}
              </div>
              <div class="table-element" style="flex: 0.3">
                <div class="row">
                  <div class="col-sm-6 col-md-4 text-center">
                    <button @click.prevent="copyScript(item)" style="margin-left: 30px;background: #0796d0;
          border: 1px solid #f0f0f0;
          -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
          color: #ffffff;
          border-radius: 5px;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0;
          text-align: center;
          width: 110px;
          height: 40px;">Copy Script
                    </button>
                  </div>
                  <div class="col-sm-6 col-md-3 text-center">
                    <button type="button" @click.prevent="changeStatus(item)" class="btn"
                            :class="item.status === 'inactive' ? 'btn-primary' : 'btn-danger'">
                      {{ item.status === 'inactive' ? 'Enable' : 'Disable' }}
                    </button>
                  </div>
                  <div class="col-sm-6 col-md-5 text-center">
                    <button type="button" @click.prevent="updateS3(item)" class="btn btn-secondary">
                      Update S3 Keys
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <modal name="s3-keys" adpative
               height="280px">
          <form style="margin-top: 20px;" action="#" @submit.prevent="updateKeys">
            <div class="row">
              <div class="col-12" style="margin-left: 40px;">Updating keys for {{ currentUser ? currentUser.displayName : null }}</div>
            </div>
            <div class="form-group row">

              <label for="name" class="col-md-4 col-form-label text-md-right">Access Key</label>

              <div class="col-md-6">
                <input
                    id="name"
                    class="form-control"
                    name="name"
                    value
                    required
                    autocomplete="off"
                    v-model="s3keys.key"
                />
              </div>
            </div>

            <div class="form-group row">
              <label for="password" class="col-md-4 col-form-label text-md-right">Access Secret</label>

              <div class="col-md-6">
                <input
                    type="password"
                    class="form-control"
                    name="password"
                    value
                    required
                    autocomplete="off"
                    v-model="s3keys.secret"
                />
              </div>
            </div>
            <div class="form-group row mb-0">
              <div class="col-md-8 offset-md-4">
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
              <div v-if="loadingReg" class="loader"></div>
            </div>
          </form>
        </modal>
      </div>
    </div>
    <div v-if="ifMDandUp" class="footer" style="">
      <div data-app>
        <v-select class="footer-select" :items="itemsPerPageArr" v-model="itemsPerPage" style="width: 50px"></v-select>
      </div>
      <div class="text-center" data-app>
        <v-pagination class="footer-pagination" color="#444444" v-model="currentPage"
                      :length="getPagesAmount"></v-pagination>
      </div>
      <div class="footer-results">Results {{ leftPageBound }}-{{ rightPageBound }} of {{ items.length }}</div>
    </div>
    <div v-else class="footer-mobile" style="">
      <div class="text-center" data-app>
        <v-pagination class="footer-pagination" color="#444444" v-model="currentPage" :length="getPagesAmount"
                      colo></v-pagination>
      </div>
      <div style="display:flex; justify-content: space-between; align-items:center; width: 100%">
        <v-select class="footer-select" :items="itemsPerPageArr" v-model="itemsPerPage"
                  style="width: 50px; max-width: 30%; padding: 0; margin: 0;"></v-select>
        <div class="footer-results">Results {{ leftPageBound }}-{{ rightPageBound }} of {{ items.length }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
button:active {
  background-color: #263853;
}

.upload-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  width: 100%;
  background: #ffffff;
  padding: 50px 70px;
  @media only screen and (max-width: 799px) {
    padding: 20px 30px;
  }
}

.upload-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 13px;
}

.upload-header .small-text {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #808080;
}

.upload-header h2 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
}

.upload-header .profile {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
}

.uploadFile {
  background: #f9f9f9;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 13px;
  margin-bottom: 20px;
}

.uploadFile-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
}

.uploadFile-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
}
.uploadFile .center {
  width: 100%;
}

.uploadFile .mobile-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.uploadFile .browse-button {
  background: #0796d0;
  border: 1px solid #f0f0f0;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  color: #ffffff;
  border-radius: 5px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 200px;
  height: 40px;
}

.create-button {
  background: #0796d0;
  border: 1px solid #f0f0f0;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  color: #ffffff;
  border-radius: 5px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 200px;
  height: 50px;
}

.uploadFile .create-button:disabled {
  background-color: rgba(38, 90, 112, 0.49);
}

.uploadFile .selected-file {
  font-family: "DM Sans", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #656565;
  padding: 0 32px;
}

.QRCodeInfo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.QRCodeInfo .upload-text {
  font-family: "DM Sans", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: rgba(101, 101, 101, 0.5);
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.footer-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;
}

.footer-results {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */
  text-align: right;
  color: #a0a0a0;
}

.footer-pagination {
  color: #000000;
}

.scan-button {
  width: 220px;
  height: 52px;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #0796d0;
  background: rgba(63, 209, 255, 0.07);
  border: 1px solid #0796d0;
  box-sizing: border-box;
  border-radius: 10px;
}

.upload-another-button {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #0796d0;
}

.alert-success {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid rgba(243, 250, 248, 1);
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #2f7669;

  .bold-text {
    font-weight: 700;
  }
}

.div-success {
  display: block;
  box-sizing: border-box;
  //height: 100%;
  h3 {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 13px;
    color: #000000;
  }

  hr {
    margin: 0;
  }

  .div-result-mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
  }

  .div-result {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 120px;
  }

  .qrcode {
    padding-right: 43px;
  }

  .qrcodeinfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 0 8px 43px;
    box-sizing: border-box;
    height: 100% !important;
    width: 100%;

    .info-line {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .keys {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "DM Sans", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #a0a0a0;
      width: 100px !important;
    }

    .values {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "DM Sans", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      word-break: break-all;
      color: #3d3d3d;

      .downoload-button {
        background: #0796d0;
        width: 200px;
        height: 30px;
        border: 1px solid #f0f0f0;
        backdrop-filter: blur(4px);
        color: white;
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 5px;
      }
    }
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.search {
  position: relative;
  margin-bottom: 28px;
}

.search-field {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 16px 0 16px 69px;
  color: rgba(0, 0, 0, 0.25);
  width: 100%;
  background: #f6f4f2;
  border-radius: 5px;
}

.search-field:focus {
  outline: none;
}

.search-field::placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.search-icon {
  position: absolute;
  left: 0;
  transform: translate(26px, 15px);
  width: 20px;
  height: 20px;
}

.tableheader {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */
  color: #a0a0a0;
  padding-bottom: 7px;
}

.tablebody {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */
  color: #656565;
}

.table-element {
  padding: 12px 0;

  .filename {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .fileicon {
    margin-right: 12px;
    background: #2c5ebe;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 5px;
    width: 50px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    color: #ffffff;
  }

  .small-time {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    /* identical to box height */
    color: #656565;
  }
}

.historytop {
  height: calc(100vh - 400px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
</style>

<script>
import firebaseApp from '../firebase.js';
import {mapGetters} from "vuex";
import moment from "moment";
import "firebase/storage";
import {getFunctions, httpsCallable} from "firebase/functions";
import "firebase/firestore";
import copy from 'copy-to-clipboard';
import axios from "axios";

export default {
  data() {
    return {
      uploadMainWidth: window.screen.width,
      items: [],
      itemsPerPage: 15,
      itemsChunks: [],
      searchFile: "",
      currentPage: 1,
      itemsPerPageArr: [10, 15, 20, 30, 35, 40, 50],
      leftindex: 0,
      rightindex: 1,
      itemsLengthIsZero: false,
      form: [],
      loadingReg: false,
      currentUser: null,
      s3keys: []
    };
  },
  computed: {
    ...mapGetters({user: "user"}),
    ifMDandUp() {
      return this.uploadMainWidth > 799;

    },
    filteredItems() {
      return this.items.filter(item => {
        return (item.displayName.toString().toUpperCase().includes(this.searchFile.toString().toUpperCase())) || (item.email.toString().toUpperCase().includes(this.searchFile.toString().toUpperCase()))
      });
    },
    getPagesAmount() {
      this.resetCurrentPage();
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
    chunkedItems() {
      let leftindex = (this.currentPage - 1) * this.itemsPerPage;
      let rightindex = leftindex + this.itemsPerPage;
      return this.filteredItems.slice(leftindex, rightindex);
    },
    leftPageBound() {
      return (this.currentPage - 1) * this.itemsPerPage + 1;
    },
    rightPageBound() {
      return (this.currentPage - 1) * this.itemsPerPage + this.itemsPerPage;
    },

  },
  watch: {
    uploadMainWidth(newWidth, oldWidth) {
      // console.log(`it changed to ${newWidth} from
      // ${oldWidth}`);
      //console.log(this.ifMDandUp);
    },
    "$store.state.windowWidth"() {
      this.onResize();
    }
  },
  async mounted() {
    await this.getClientData();
    this.itemsLengthIsZero = !this.items.length;
  },
  methods: {
    itemHref(fileId) {
      return `${document.location.origin}/?fileId=${fileId}`;
    },
    itemRouterLink(fileId, chain) {
      return `/?fileId=${fileId}&chain=${chain}`;
    },
    async getClientData() {
      this.loadingReg = true;
      const functions = getFunctions(firebaseApp);
      const ClientData = httpsCallable(functions, 'getClients');
      this.items = (await ClientData()).data.clients;
      this.loadingReg = false;

    },
    onResize() {
      this.uploadMainWidth = this.$refs.clientTable.clientWidth;
    },
    getItemsChunk() {
      return this.itemsChunks;
    },
    resetCurrentPage() {
      this.currentPage = 1;
    },
    async registerClient() {
      this.loadingReg = true;
      const functions = getFunctions(firebaseApp);
      const createClient = httpsCallable(functions, 'createClient');
      const createDomain = httpsCallable(functions, 'createUserUrl');
      const checkDomainAvailability = httpsCallable(functions, 'checkDomainAvailability');
      const availability = await checkDomainAvailability({
        domain: this.form.domain
      })
      if (!availability.data) {
        this.loadingReg = false
        this.$toast.error('Domain is already registered');
      } else {
        await createClient({
          UserName: this.form.name,
          UserEmail: this.form.email,
          UserPassword: this.form.password,
          parentUserId: this.user.data.uid,
        }).then(async (response) => {
          await createDomain({
            clientId: response.data.uid,
            name: 'basic',
            domain: this.form.domain
          }).then(() => {
            this.$toast.success('Client created successfully');
            this.$modal.hide('client-register')
            this.getClientData()
          })
        }).catch(err => {
          this.error = err
        })
      }
      this.loadingReg = false
    },
    formatDate(s) {
      var dateAsDateObject = new Date(Date.parse(s));
      return moment(dateAsDateObject).format('LLL');
    },
    copyScript() {
      copy(`<script data-name="bmc-script" src="https://gigcodes-bmc.netlify.app/bmc-legacy.js"></\script>`);
    },
    async changeStatus(item) {
      if (confirm(item.status === 'inactive' ? 'Are you sure to enable ?' : 'Are you sure to disable ?') === true) {
        this.loadingReg = true;
        const functions = getFunctions(firebaseApp);
        const changeClientStatus = httpsCallable(functions, 'changeClientStatus');
        const data = {
          clientId: item.uid,
          parentUserId: this.user.data.uid,
          status: item.status === 'inactive' ? 'active' : 'inactive'
        }
        await changeClientStatus(data).then(() => {
          this.getClientData()
        })
      } else {

      }
    },

    getTransactionCount(transactions) {
      if (transactions.length) {
        let count = 0, addressVerified = false, identityVerified = false;
        transactions.forEach(transaction => {
          if (transaction.sub_transactions) {
            transaction.sub_transactions.forEach(item => {
              if (item.type === 'identity' && item.status === true) {
                identityVerified = true;
              }
              if (item.type === 'address' && item.status === true) {
                addressVerified = true;
              }
            })

            if (addressVerified && identityVerified) {
              count++;
              addressVerified = false;
              identityVerified = false;
            }
          }

        })
        return count;
      } else {
        return "N/A"
      }
    },

    updateS3(item) {
      this.currentUser = item;
      this.$modal.show('s3-keys')
    },
    async updateKeys() {
      this.loadingReg = true;
      await axios.post("https://bmc-node-352801.el.r.appspot.com/s3/create-credentials", {
        clientId: this.currentUser.uid,
        access_key: this.s3keys.key,
        access_secret: this.s3keys.secret
      })
      this.$modal.hide('s3-keys')
      this.loadingReg = false;
    }
  }
};
</script>

<style lang="scss">
.rwd-table {
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
}

.rwd-table tr:first-child {
  border-top: none;
  background: #428bca;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: #333;
  border-radius: .4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: .5em 1em;
}

@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
}

@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
}
</style>