<template>
  <div>
    <NavBarVue/>
    <div class="main-body-div">
      <div class="idnetity-main">
        <h3 class="pt-4 pb-2 text-center">Service Unavailable</h3>
        <p class="font-12 text-center">Please <a href="mailto:admin@bmcdubai.com">contact support</a> for more information.
        </p>
      </div>
    </div>
    <FooterCompVue/>
  </div>
</template>


<script>
import NavBarVue from '../components/new-components/NavBar.vue';
import FooterCompVue from '../components/new-components/FooterComp.vue';

export default {
  
  name: 'Verified',
  components: {
    NavBarVue,
    FooterCompVue
  }
}
</script>

<style scoped>
a {
  color: #0796D0;
  text-decoration: underline !important;
}
a:hover {
  color: #0584b6 !important;
}

</style>