import firebaseApp from '../firebase.js';
import {getFunctions, httpsCallable} from "firebase/functions";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: {
            loggedIn: false,
            data: null,
            isAdmin: false,
        },
        sidebarHidden: true,
        windowWidth: 0,
        verifiedUser: false,
        clientStatus: null,
        kycData: {}
    },

    getters: {
        user(state) {
            return state.user;
        },
        sidebarHidden(state) {
            return state.sidebarHidden;
        },
        windowWidth(state) {
            return state.windowWidth;
        },
        verifiedUser(state) {
            return state.verifiedUser;
        },
        kycData(state) {
            return state.kycData;
        },
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_IS_ADMIN(state, value) {
            state.user.isAdmin = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
        TOGGLE_SIDEBAR_HIDDEN(state) {
            state.sidebarHidden = !state.sidebarHidden;
        },
        UPDATE_WINDOW_WIDTH(state) {
            state.windowWidth = window.innerWidth;
        },
        SET_VERIFIED_USER(state, data) {
            state.verifiedUser = data;
        },
        SET_KYC_DATA(state, data) {
            state.kycData = data;
        },
        SET_CLIENT_STATUS(state, status){
            state.clientStatus = status
        }
    },
    actions: {
        async userAuthStateChanged({commit, dispatch}, user) {
            commit("SET_LOGGED_IN", user !== null);
            if (user) {
                let role = 'user';
                try {
                    role = JSON.parse(user.reloadUserInfo.customAttributes).role
                    commit("SET_IS_ADMIN", role === "admin")
                } catch (e) {
                    //do nothing.
                }
                commit("SET_USER", {
                    displayName: user.displayName,
                    email: user.email,
                    uid: user.uid,
                    role
                });
            } else {
                commit("SET_USER", null);
            }
        },
        async adminCheck({commit}) {
            const functions = getFunctions(firebaseApp);
            const isAdmin = (await httpsCallable(functions, 'AdminCheck')()).data.adm;
            commit("SET_IS_ADMIN", isAdmin);
        },
        setWindowWidth({commit}) {
            commit("UPDATE_WINDOW_WIDTH")
        }
    },
});
