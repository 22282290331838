<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Register</div>
          <div class="card-body">
            <div v-if="error" class="alert alert-danger">{{error}}</div>
            <form action="#" @submit.prevent="submit">
              <div class="form-group row">
                <label for="name" class="col-md-4 col-form-label text-md-right">Name</label>

                <div class="col-md-6">
                  <input
                    id="name"
                    type="name"
                    class="form-control"
                    name="name"
                    value
                    required
                    autofocus
                    v-model="form.name"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="email" class="col-md-4 col-form-label text-md-right">Email</label>

                <div class="col-md-6">
                  <input
                    id="email"
                    type="email"
                    class="form-control"
                    name="email"
                    value
                    required
                    autofocus
                    v-model="form.email"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                <div class="col-md-6">
                  <input
                    id="password"
                    type="password"
                    class="form-control"
                    name="password"
                    required
                    v-model="form.password"
                  />
                </div>
              </div>

              <div class="form-group row mb-0">
                <div class="col-md-8 offset-md-4">
                  <button type="submit" class="btn btn-primary">Register as user</button>
                  <button type="submit" class="btn btn-primary" @click="isAdmin()">Register as Admin</button>
                </div>
                <div v-if="loadingReg" class="loader"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import firebase from "firebase/app";
import {getFunctions, httpsCallable} from "firebase/functions";
import firebaseApp from "@/firebase";
export default {
  data() {
    return {
      goodAdmin: false,
      form: {
        name: "",
        email: "",
        password: ""
      },
      error: null,
      loadingReg: false,
    };
  },
  methods: {
    async isAdmin() {
      this.goodAdmin = true
      // console.log("is admin")
    },
    async submit() {
      this.loadingReg = true
      const functions = getFunctions(firebaseApp);
      const createUser = httpsCallable(functions, 'createUser');
      await createUser({
        UserName: this.form.name,
        UserEmail: this.form.email,
        UserPassword: this.form.password,
        addAdmin: this.goodAdmin,
      }).then((result) => {
        this.res = result.data.mes
        // console.log(result.data.mes)
      })
      .catch(err => {
        this.error = err
      })
      this.loadingReg = false
      firebase
        .auth()
        .createUser(this.form.email, this.form.password)
        .then(data => {
          data.user
            .updateProfile({
              displayName: this.form.name
            })
            .then(() => {});
        })
        .catch(err => {
          if (err == "Error: The email address is already in use by another account.") {
            err = "Error: The username is already in use by another account."
          }
          this.error = err;
      });
    },
    // async newAdmin() {
    //   const addAdmin = firebase.functions().httpsCallable('addAdmin')
    //   await addAdmin({newAdmin: this.form.email}).then((result) => {
    //     //console.log(result.data.adm);
    //     console.log(result.data.mes)
    //   });
    // }
  }
};
</script>

<style lang="scss" scoped>
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>