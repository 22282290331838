<template>
    <div class="cam-main-dashboard">

        <!-- main body tag -->
        <div class="body-div" >
            <div class="main-uploadDoc">

                <!---------------- header Start----------------->
                <div class="dashboard-header">
                    <div class="heading-area">
                        <HeadDropdown/>
                        <h2>Update Profile</h2>
                        <p class="text-start pt-2"><strong>Current Balance:</strong> {{totalCredit}} credits</p>
                    </div>
                </div>
                <!---------------- header End----------------->


                <!-- Inner body tag -->
                <div class="pt-4" v-if="dataloads">
                    <div class="main-body-div">
                        <b-form>
                            <div class="row">
                                <div class="col-6">
                                    <h3 class="d-flex">Personal Information</h3>
                                    <p class="d-flex">Please provide your Personal Information</p>
                                    <div class="auth-main">
                                        <div class="pb-2">
                                            <div class="pt-2 pb-2">
                                                <div class="error-block" v-if="(error.length > 0)">
                                                    <div class="error-block-img"> 
                                                        <img src="../../assets/images/error-icon.svg" />
                                                    </div>
                                                    <span>Email or password is incorrect.</span>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6 py-0">
                                                        <label for="input-0" class="input-label">First Name</label>
                                                        <b-form-input
                                                            id="input-0"
                                                            type="text"
                                                            placeholder="First Name"
                                                            class="form-fields mt-1"
                                                            v-bind:class="{'input-error': error.length > 0}"
                                                            v-model="form.firstname"
                                                            v-on:change="formChanged"
                                                        >
                                                        </b-form-input>
                                                    </div>
                                                    <div class="col-6 py-0">
                                                        <label for="input-01" class="input-label">Last Name</label>
                                                        <b-form-input
                                                            id="input-01"
                                                            type="text"
                                                            placeholder="Last Name"
                                                            class="form-fields mt-1"
                                                            v-bind:class="{'input-error': error.length > 0}"
                                                            v-model="form.lastname"
                                                            v-on:change="formChanged"
                                                        >
                                                        </b-form-input>
                                                    </div>
                                                </div>
                                                <label for="input-1" class="input-label mt-3">Email</label>
                                                <b-form-input
                                                    id="input-1"
                                                    type="email"
                                                    placeholder="Email"
                                                    class="form-fields mt-1"
                                                    v-bind:class="{'input-error': error.length > 0}"
                                                    v-model="form.email"
                                                    v-on:change="formChanged"
                                                >
                                                </b-form-input>
                                                <label for="input-2" class="input-label mt-3">Phone Number</label>
                                                <b-form-input
                                                    id="input-2"
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    class="form-fields mt-1"
                                                    v-bind:class="{'input-error': error.length > 0}"
                                                    v-model="form.phone"
                                                    v-on:change="formChanged"
                                                >
                                                </b-form-input>
                                                <label for="input-3" class="input-label mt-3">Company Name</label>
                                                <b-form-input
                                                    id="input-3"
                                                    type="text"
                                                    placeholder="Company Name"
                                                    class="form-fields mt-1"
                                                    v-bind:class="{'input-error': error.length > 0}"
                                                    v-model="form.companyname"
                                                    v-on:change="formChanged"
                                                >
                                                </b-form-input>
                                                <label for="input-4" class="input-label mt-3">Password</label>
                                                <b-form-input
                                                    id="input-4"
                                                    type="password"
                                                    placeholder="********"
                                                    class="form-fields mt-1"
                                                    v-bind:class="{'input-error': error.length > 0}"
                                                    v-model="form.password"
                                                    v-on:change="formChanged"
                                                >
                                                </b-form-input>
                                                <label for="input-5" class="input-label mt-3">Confirm Password</label>
                                                <b-form-input
                                                    id="input-5"
                                                    type="password"
                                                    placeholder="********"
                                                    class="form-fields mt-1"
                                                    v-bind:class="{'input-error': error.length > 0}"
                                                    v-model="form.password2"
                                                    v-on:change="formChanged"
                                                >
                                                </b-form-input>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div class="col-6">
                                    <h3 class="d-flex">Business Information</h3>
                                    <p class="d-flex">Business address and information</p>
                                    <div class="auth-main">
                                        <div class="pb-2">
                                            <div class="pt-2 pb-2">
                                                <label for="input-6" class="input-label">Address line 1</label>
                                                <b-form-input
                                                    id="input-6"
                                                    type="text"
                                                    placeholder="Address line 1"
                                                    class="form-fields mt-1"
                                                    v-bind:class="{'input-error': error.length > 0}"
                                                    v-model="form.address1"
                                                    v-on:change="formChanged"
                                                >
                                                </b-form-input>
                                                <label for="input-7" class="input-label mt-3">Address line 2</label>
                                                <b-form-input
                                                    id="input-7"
                                                    type="text"
                                                    placeholder="Address line 2"
                                                    class="form-fields mt-1"
                                                    v-bind:class="{'input-error': error.length > 0}"
                                                    v-model="form.address2"
                                                    v-on:change="formChanged"
                                                >
                                                </b-form-input>
                                                <div class="row">
                                                    <div class="col-6 py-0">
                                                        <label for="input-8" class="input-label mt-3">City</label>
                                                        <b-form-input
                                                            id="input-8"
                                                            type="text"
                                                            placeholder="City"
                                                            class="form-fields mt-1"
                                                            v-bind:class="{'input-error': error.length > 0}"
                                                            v-model="form.city"
                                                            v-on:change="formChanged"
                                                        >
                                                        </b-form-input>
                                                    </div>
                                                    <div class="col-6 py-0">
                                                        <label for="input-9" class="input-label mt-3">State (Optional)</label>
                                                        <b-form-input
                                                            id="input-9"
                                                            type="text"
                                                            placeholder="State (Optional)"
                                                            class="form-fields mt-1"
                                                            v-bind:class="{'input-error': error.length > 0}"
                                                            v-model="form.state"
                                                            v-on:change="formChanged"
                                                        >
                                                        </b-form-input>
                                                    </div>
                                                </div>
                                                <label for="input-10" class="input-label mt-3">Postal Code</label>
                                                <b-form-input
                                                    id="input-10"
                                                    type="text"
                                                    placeholder="Postal Code"
                                                    class="form-fields mt-1"
                                                    v-bind:class="{'input-error': error.length > 0}"
                                                    v-model="form.postalcode"
                                                    v-on:change="formChanged"
                                                >
                                                </b-form-input>
                                                <label class="input-label mt-3">Country</label>
                                                <div class="radio-div-slct selct-field">

                                                    <b-form-select class="form-select selct-opt" v-model="form.country" >
                                                        <option :value="null" disabled>Select Country</option>
                                                        <option v-for="option in options" :value="option.code">
                                                        {{ option.name }}
                                                        </option>
                                                    </b-form-select>
                                                </div>
                                                <label for="input-11" class="input-label mt-3">BMC KYC URL</label>
                                                <b-form-input
                                                    id="input-11"
                                                    type="text"
                                                    placeholder="https://kyc.ctech.co.uk/"
                                                    class="form-fields mt-1"
                                                    v-bind:class="{'input-error': error.length > 0}"
                                                    v-model="form.domain"
                                                    :disabled="true"
                                                >
                                                </b-form-input>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div class="btn-div">
                                    <button class="main-btn" @click.prevent="updateClient()">
                                    Update
                                    <b-spinner small v-if="loading"></b-spinner>
                                    </button>
                                </div>
                            </div>
                        </b-form>
                    </div>
                </div>
                <template v-if="!dataloads">
                    <div class="main-body-div">
                        <div class="navbar-main">
                        <b-container class="pt-4 centered">
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                            <div>
                            <p class="font-12 text-center">Loading...</p>
                            </div>
                        </b-container>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>

</template>
  
  
<script>
  import HeadDropdown from '../new-components/headDropdown.vue';
  import {getAuth, updateProfile, updatePassword} from "firebase/auth";
  import countries from "../../assets/countries.json";
  import axios from 'axios';

  export default {

    name: 'Profile',
    components: {
        HeadDropdown
    },
    data() {
        return {
            dataloads: false,
            options: countries,
            loading: false,
            error: "",
            form: {
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                companyname: "",
                password: "",
                password2: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                postalcode: "",
                country: null,
                domain: ""
            },
            totalCredit: 0
        };
    },
    mounted() {
        const uid = localStorage.getItem("uid");
        this.getdata(uid);
        this.getCredits(uid);
    },
    methods: {
        formChanged: function() {
        this.error = ""
        },
        async submit() {
            this.loading = true;
            
            
            this.loading = false;
            console.log('res', auth.currentUser)
            // updateProfile(auth, {displayName: this.displayName});
            // if (this.form.password === this.form.password2) {
            //     updatePassword(auth, this.form.password)
            //     .then((res) => {
            //         // localStorage.setItem("displayName", res.user.displayName)
            //         // this.login = true;
            //         // this.$router.push({name: "overview"});
            //         this.loading = false;
            //     })
            //     .catch((err) => {
            //         this.error = err.message;
            //         this.loading = false;
            //     });
            // }
            
        },
        getdata(id) {
            axios.post('https://kycapi.aml-verify.com/getClientByID', {uid:id}).then( (res) => {
                console.log('getClientByID', res)
                if(res.status === 200) {
                    this.dataloads = true;
                    const data = res.data;
                    this.form.firstname = data.firstname;
                    this.form.lastname = data.lastname;
                    this.form.email = data.email;
                    this.form.phone = data.phone;
                    this.form.companyname = data.companyname;
                    this.form.address1 = data.address1;
                    this.form.address2 = data.address2;
                    this.form.city = data.city;
                    this.form.state = data.state;
                    this.form.postalcode = data.postalcode;
                    this.form.country = data.country;
                    this.form.domain = data.domain;
                    this.form.clientId = data.clientId;
                }
            }).catch((err) => {
                console.log('getClientByID err', err)
            });
        },
        getCredits(id) {
            axios.post('https://kycapi.aml-verify.com/getCreditsByID', {uid:id}).then( (res) => {
                if(res.status === 200) {
                    this.totalCredit = res.data;
                }
            }).catch((err) => {
                console.log('getClientByID err', err)
            });
        },
        async updateClient() {
            this.loading = true;
            const auth = getAuth();
            await updateProfile(auth.currentUser, {
                displayName: this.form.firstname + ' ' + this.form.lastname
            });
            localStorage.setItem("displayName", this.form.firstname + ' ' + this.form.lastname);
            if(this.form.password !== "" && this.form.password2 !== "" && this.form.password === this.form.password2) {
                await updatePassword(auth.currentUser, {
                    newPassword: this.form.password
                });
            }
            delete(this.form.checked);
            this.form.status = "Active";
            axios.post("https://kycapi.aml-verify.com/updateClientByID", {
                data: this.form
            }).then(() => {
                this.loading = false;
                this.success = "Client created successfully";
                this.$router.push({name: "success"});
            }).catch(err => {
                this.loading = false;
                this.error = "Unable to created Client";
            })
        },
    },
  }
</script>
  
<style scoped>
  .main-body-div{
    height: unset !important;
  }
  .input-label {
    display: flex;
    font-weight: 600;
    font-size: 14px;
  }
</style>
<style scoped>
  .centered {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 5px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0796D0 #ccc #ccc #ccc;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>