<template>
  <div>
    <NavBarVue/>
    <div class="main-body-div">
        <div class="verification-main">
          <div class="pb-3">
            <p class="font-14 pb-2">
              <b>Enter the verification code</b>
            </p>
            <p class="font-12 pb-1 para-width">
              A text message with a six-digit verification code has 
              been to your phone number ending in *****{{phone?.slice(-3)}}
            </p>
            <div class="">
              <div class="py-2">
              <div class="error-block" v-if="(error.length > 0)">
                <div class="error-block-img"> 
                  <img src="../assets/images/error-icon.svg" />
                </div>
                <span>{{error}}</span>
              </div>
            </div>
            <!-- v-on:keyup.enter="verifyOTP" -->
              <b-form>
                <b-form-input
                  id="code"
                  type="number"
                  placeholder="Verification Code"
                  class="form-fields mt-3 text-center numeric-input"
                  v-model="code"
                  required
                  v-on:keydown.enter.prevent='verifyOTP'
                  autocomplete="off"
                  controls=false
                >
                </b-form-input>
                <!-- v-on:change="formChanged" -->
              </b-form>
            </div>
          </div>
        
          
          <div class="btn-div mt-2">
            <button class="main-btn" role="button" @click="verifyOTP()" :disabled="(code === '' || phone === undefined)">
              Verify
              <b-spinner small v-if="loading"></b-spinner>
            </button>
          </div>
          <div class="auth-btn-div pt-2">
            <p class="gray-btn fw-light" >Haven't receive the code?</p>
            <button class="gray-btn" @click="resendOTP()">Resend code</button>
          </div>
          <div class="back-btn text-center pt-4" @click="personalInfo()">
            <!-- <img src="../assets/images/Left-arrow-gray.svg" alt=""> -->
            <i data-feather="arrow-left" stroke="gray" stroke-width="3" height="18"></i>
            <button class="gray-btn ps-2">Edit Personal Information</button>
          </div>
        </div>
    </div>
    <FooterCompVue/>
  </div>
</template>


<script>

import NavBarVue from '../components/new-components/NavBar.vue';
import FooterCompVue from '../components/new-components/FooterComp.vue';
import axios from 'axios';
import feather from 'feather-icons';
import { mapGetters } from "vuex";

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
const id = uuidv4();

export default {
  computed: {
    ...mapGetters({
      verifiedUser: "verifiedUser",
    }),
  },
  name: 'VerificationCode',
  components: {
    NavBarVue,
    FooterCompVue
  },
  data() {
    return {
      error: "",
      success: "",
      loading: false,
      code: '',
      phone: ''
    }
  },
  mounted() {
    console.log('kycData', this.$store.state.kycData)
    this.phone = this.$store.state.kycData?.phone;
    feather.replace();
  },
  // watch: {
  //   code(value) {
  //     console.log("value", value);
  //   },
  // },
  methods: {
    formChanged: function() {
      this.error = ""
    },
    async verifyOTP() {
      if(this.code !== '') {
        this.loading = true;
        axios.post('https://kycapi.aml-verify.com/verify-otp', {
          id: id,
          otp: parseInt(this.code),
          phone: this.phone
        }).then(async (res) => {
          if (res.data.verified) {
            this.success = "OTP validated successfully";
            this.loading = false;
            await this.$store.commit("SET_VERIFIED_USER", true);
            this.$router.push({name: "document-info" });
          } else {
            this.error = "Invalid verification code. Please try again.";
            this.loading = false;
          }
        }).catch(() => {
          this.loading = false;
          this.error = "Invalid verification code. Please try again.";
        })
      }
    },
    resendOTP() {
      axios.post("https://kycapi.aml-verify.com/sent-otp", {
        form: {
          phone: this.phone,
        },
        id: id
      }).then(() => {
        this.loading = false;
        this.success = "OTP sent successfully";
      }).catch(err => {
        this.loading = false;
        this.error = "Unable to sent OTP";
      })
    },
    personalInfo() {
      this.$router.push({ name: 'personal-info', params: {checked: true} })
    }
  }
}
</script>

<style scoped>
  .numeric-input::-webkit-outer-spin-button, .numeric-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>