<template class="">
  <div class="bmc-main-warp">
    <RouterView />
  </div>
 
</template>

<script>
import Sidebar from "./components/Sidebar";
import { mapGetters } from "vuex";
export default {
  components: {
    Sidebar
  },
  computed: {
    ...mapGetters({
      user: "user",
      sidebarHidden: "sidebarHidden",
      windowWidth: "windowWidth"
    }),
    mainHidden() {
      return !this.sidebarHidden && this.windowWidth < 800;
    },
    mainStyle() {
      return [this.mainHidden ? "display: none" : "display: flex", this.$route.name === 'login' ? 'padding-left: 0' : this.$store.getters.sidebarHidden ? "padding-left: 70px" : "padding-left: 318px"].join("; ");
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH");
    }
  }
};
</script>
<!-- <style>
@import "./static/app.css";
</style> -->
<style scoped>
  body{
    font-family: 'Inter', sans-serif !important;
  }
  .bmc-main-warp{
    width: 100%;
  }
</style>