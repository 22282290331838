<template>
  <div>
    <NavBarVue/>
    <div class="main-body-div">
        <div class="per-info-main">
          <h3 class="pb-2">Personal Information</h3>
          <p class="font-12">To get started, tell us about yourself.</p>
  
          <div class="pt-3 pb-2">
            <div class="pb-2">
              <div class="error-block" v-if="(error.length > 0)">
                <div class="error-block-img"> 
                  <img src="../assets/images/error-icon.svg" />
                </div>
                <span>Unable to sent OTP</span>
              </div>
            </div>
            <b-form>
              <div class="input-hf">
                <b-form-input
                id="firstname"
                :state="$v.form.firstname.$dirty ? !$v.form.firstname.$error : null"
                placeholder="First name"
                class="form-fields custom-form-width"
                v-model="form.firstname"
                v-on:change="formChanged"
                >
                </b-form-input>
                <b-form-input
                  id="lastname"
                  placeholder="Last name"
                  class="form-fields custom-form-width"
                  v-model="form.lastname"
                  v-on:change="formChanged"
                >
                </b-form-input>
              </div>
              <b-form-input
                id="email"
                :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
                type="email"
                placeholder="Email"
                class="form-fields mt-3"
                v-model="form.email"
                v-on:change="formChanged"
              >
              </b-form-input>
              <div class="phone-div mt-3">
                <VuePhoneNumberInput 
                  :default-country-code="countryCode"
                  v-model="form.phone"
                  :state="$v.form.phone.$dirty ? !$v.form.phone.$error : null"
                  @update="onUpdate"
                />
              </div>
            </b-form>
          </div>
          
          <div class="btn-div mt-2">
            <button class="main-btn" role="button" @click="sendOTP()">
              Continue
              <b-spinner small v-if="loading"></b-spinner>
            </button>
          </div>
          
        </div>
    </div>
    <FooterCompVue/>
  </div>
</template>


<script>
  const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  const id = uuidv4();
  import NavBarVue from '../components/new-components/NavBar.vue';
  import FooterCompVue from '../components/new-components/FooterComp.vue';
  import axios from 'axios';
  import VuePhoneNumberInput from "vue-phone-number-input";
  import { validationMixin } from 'vuelidate';
  import { email, required, minLength } from 'vuelidate/lib/validators';
  import { mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters({
        kycData: "kycData",
      }),
    },
    mixins: [validationMixin],
    name: 'PersonalInfo',
    components: {
      NavBarVue,
      FooterCompVue,
      VuePhoneNumberInput,
    },
    data() {
      return {
        error: "",
        success: "",
        loading: false,
        phoneNumber: '',
        countryCode: 'US',
        form: {
          firstname: '',
          lastname: '',
          email: '',
          phone: ''
        },
      };
    },
    mounted() {
      console.log('this.kycData', this.kycData);
      this.form.firstname = this.kycData.firstname !== undefined ? this.kycData.firstname : '';
      this.form.lastname = this.kycData.lastname !== undefined ? this.kycData.lastname : '';
      this.form.email = this.kycData.email !== undefined ? this.kycData.email : '';
      this.form.phone = this.kycData.phone !== undefined ? this.kycData.phone : '';
      this.countryCode = this.kycData.country !== undefined ? this.kycData.country : 'US';
    },
    validations: {
      form: {
        firstname: { required, minLength: minLength(3) },
        email: { email, required },
        phone: { required },
      }
    },
    methods: {
      formChanged: function() {
        this.error = ""
      },
      onUpdate (payload) {
        this.phoneNumber = payload.formattedNumber;
        this.countryCode = payload.countryCode;
      },
      async sendOTP(){
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        } else {
          this.loading = true;
          axios.post("https://kycapi.aml-verify.com/sent-otp", {
            form: {
              name: this.form.firstname + ' ' + this.form.lastname,
              email: this.form.email,
              phone: this.phoneNumber,
              country: this.countryCode,
            },
            id: id
          }).then(async () => {
            this.loading = false;
            this.success = "OTP sent successfully";
            const form = {
              firstname: this.form.firstname,
              lastname: this.form.lastname,
              name: this.form.firstname + ' ' + this.form.lastname,
              email: this.form.email,
              phone: this.phoneNumber,
              country: this.countryCode,
            }
            await this.$store.commit("SET_KYC_DATA", form);
            this.$router.push({name: "verification-code", params: {phone: this.phoneNumber !== '' ? true : false}});
          }).catch(err => {
            this.loading = false;
            console.log('sendOTP', err)
            this.error = "Unable to sent OTP";
          })
        }
      }
    }
  }
</script>

<style scoped>
::v-deep .select-country-container{
  margin-right: 10px !important;
}
::v-deep .input-tel__input, ::v-deep .country-selector__input{
  background: #FFFFFF;
  border: 1px solid #EAEAEA !important;
  border-radius: 5px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05));
  color: #06212C;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  height: 46px !important;
}
::v-deep .input-tel__input::placeholder {
  color: #cfcfcf !important;
}
::v-deep .form-control.is-valid {
  border-color: #EAEAEA !important;
  background-image: none !important;
}
</style>