<template>
  <div v-if="user.loggedIn" ref="uploadmain" class="upload-main">
    <div class="historytop">
      <div class="upload-header">
        <div class="header">
          <h2>Client User Data</h2>
        </div>
        <div class="profile">{{ user.data.displayName }}</div>
      </div>
      <div class="search">
        <input v-model="searchFile" class="search-field" type="search" placeholder="Search..."/>
        <img src="../static/search.png" class="search-icon"/>
      </div>
      <div
          v-if="!items.length && !itemsLengthIsZero"
          style="
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div class="loader"></div>
      </div>
      <div class="history-mobile" v-else-if="!ifMDandUp" style="width: 100%; max-height: 100%">
        <div
            class="tableheader"
            style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <div style="flex: 0.9">Name</div>
          <div style="flex: 0.1">Action</div>
        </div>
        <hr/>
        <div class="tablebody" style="width: 100%; max-height: 100%; overflow-y: auto !important">
          <div v-for="(item, i) in chunkedItems" :key="i">
            <div
                style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
              "
            >
              <div class="table-element" style="flex: 0.9">
                <div class="filename">
                  {{ item.user.name }}
                </div>
              </div>
              <div class="table-element" style="flex: 0.1">
                <router-link :to="{ name: 'history-documents', params: { id: item.id }}">show</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="history" style="width: 100%; max-height: 100%">
        <div
            class="tableheader"
            style="
            display: flex;
            justify-content: flex-start;
            align-items: center;"
        >
          <div style="flex: 0.2">ID</div>
          <div style="flex: 0.2">Name</div>
          <div style="flex: 0.2">Email</div>
          <div style="flex: 0.2">Successful Transaction</div>
          <div style="flex: 0.2">Date</div>
        </div>
        <hr/>

        <div class="tablebody" style="width: 100%; max-height: 100%; overflow-y: scroll !important">
          <div v-for="(item, i) in chunkedItems" :key="i">
            <div
                style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
              "
            >
              <div class="table-element" style="flex: 0.2">
                {{ i + 1 }}
              </div>
              <div class="table-element" style="flex: 0.2">
                <div class="filename">
                  {{ item.user.name }}
                </div>
              </div>
              <div class="table-element" style="flex: 0.2">
                <div class="filename">
                  {{ item.user.email }}
                </div>
              </div>
              <div class="table-element" style="flex: 0.2">
                {{ getTransactionCount(item.sub_transactions) ? 'Verified' : 'Not Verified' }}
              </div>
              <div class="table-element" style="flex: 0.2">
                <div>{{ formatDateTwo(item.createdAt).date }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="ifMDandUp" class="footer" style="">
      <div data-app>
        <v-select class="footer-select" :items="itemsPerPageArr" v-model="itemsPerPage" style="width: 50px"></v-select>
      </div>
      <div class="text-center" data-app>
        <v-pagination class="footer-pagination" color="#444444" v-model="currentPage"
                      :length="getPagesAmount"></v-pagination>
      </div>
      <div class="footer-results">Results {{ leftPageBound }}-{{ rightPageBound }} of {{ items.length }}</div>
    </div>
    <div v-else class="footer-mobile" style="">
      <div class="text-center" data-app>
        <v-pagination class="footer-pagination" color="#444444" v-model="currentPage" :length="getPagesAmount"
                      colo></v-pagination>
      </div>
      <div style="display:flex; justify-content: space-between; align-items:center; width: 100%">
        <v-select class="footer-select" :items="itemsPerPageArr" v-model="itemsPerPage"
                  style="width: 50px; max-width: 30%; padding: 0; margin: 0;"></v-select>
        <div class="footer-results">Results {{ leftPageBound }}-{{ rightPageBound }} of {{ items.length }}</div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
button:active {
  background-color: #263853;
}

.upload-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  width: 100%;
  background: #ffffff;
  padding: 50px 70px;
  @media only screen and (max-width: 799px) {
    padding: 20px 30px;
  }
}

.upload-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 13px;
}

.upload-header .small-text {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #808080;
}

.upload-header h2 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
}

.upload-header .profile {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
}

.uploadFile {
  background: #f9f9f9;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 13px;
  margin-bottom: 20px;
}

.uploadFile-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
}

.uploadFile-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
}

.uploadFile .left,
.right {
}

.uploadFile .center {
  width: 100%;
}

.uploadFile .mobile-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.uploadFile .browse-button {
  background: #0796d0;
  border: 1px solid #f0f0f0;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  color: #ffffff;
  border-radius: 5px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 200px;
  height: 40px;
}

.create-button {
  background: #0796d0;
  border: 1px solid #f0f0f0;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  color: #ffffff;
  border-radius: 5px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 200px;
  height: 50px;
}

.uploadFile .create-button:disabled {
  background-color: rgba(38, 90, 112, 0.49);
}

.uploadFile .selected-file {
  font-family: "DM Sans", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #656565;
  padding: 0 32px;
}

.QRCodeInfo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.QRCodeInfo .upload-text {
  font-family: "DM Sans", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: rgba(101, 101, 101, 0.5);
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.footer-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;
}

.footer-results {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */
  text-align: right;
  color: #a0a0a0;
}

.footer-select {
}

.footer-pagination {
  color: #000000;
}

.scan-button {
  width: 220px;
  height: 52px;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #0796d0;
  background: rgba(63, 209, 255, 0.07);
  border: 1px solid #0796d0;
  box-sizing: border-box;
  border-radius: 10px;
}

.upload-another-button {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #0796d0;
}

.alert-success {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid rgba(243, 250, 248, 1);
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #2f7669;

  .bold-text {
    font-weight: 700;
  }
}

.div-success {
  display: block;
  box-sizing: border-box;
  //height: 100%;
  h3 {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 13px;
    color: #000000;
  }

  hr {
    margin: 0;
  }

  .div-result-mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
  }

  .div-result {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 120px;
  }

  .qrcode {
    padding-right: 43px;
  }

  .qrcodeinfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 0 8px 43px;
    box-sizing: border-box;
    height: 100% !important;
    width: 100%;

    .info-line {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .keys {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "DM Sans", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #a0a0a0;
      width: 100px !important;
    }

    .values {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "DM Sans", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      word-break: break-all;
      color: #3d3d3d;

      .downoload-button {
        background: #0796d0;
        width: 200px;
        height: 30px;
        border: 1px solid #f0f0f0;
        backdrop-filter: blur(4px);
        color: white;
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 5px;
      }
    }
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.search {
  position: relative;
  margin-bottom: 28px;
}

.search-field {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 16px 0 16px 69px;
  color: rgba(0, 0, 0, 0.25);
  width: 100%;
  background: #f6f4f2;
  border-radius: 5px;
}

.search-field:focus {
  outline: none;
}

.search-field::placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.search-icon {
  position: absolute;
  left: 0;
  transform: translate(26px, 15px);
  width: 20px;
  height: 20px;
}

.tableheader {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */
  color: #a0a0a0;
  padding-bottom: 7px;
}

.tablebody {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */
  color: #656565;
}

.table-element {
  padding: 12px 0;

  .filename {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .fileicon {
    margin-right: 12px;
    background: #2c5ebe;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 5px;
    width: 50px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    color: #ffffff;
  }

  .small-time {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    /* identical to box height */
    color: #656565;
  }
}

.historytop {
  height: calc(100vh - 400px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
</style>

<script>
import firebaseApp from '../firebase.js';
import {mapGetters} from "vuex";
import moment from "moment";
import "firebase/storage";
import {getFunctions, httpsCallable} from "firebase/functions";
import "firebase/firestore";

export default {
  data() {
    return {
      uploadMainWidth: window.screen.width,
      items: [],
      itemsPerPage: 15,
      itemsChunks: [],
      searchFile: "",
      currentPage: 1,
      itemsPerPageArr: [10, 15, 20, 30, 35, 40, 50],
      leftindex: 0,
      rightindex: 1,
      itemsLengthIsZero: false,
    };
  },
  computed: {
    ...mapGetters({user: "user"}),
    ifMDandUp() {
      if (this.uploadMainWidth > 799) return true;
      return false;
    },
    filteredItems() {
      return this.items.filter(item => {
        return (item.user.name.toString().toUpperCase().includes(this.searchFile.toString().toUpperCase())) || (item.user.email.toString().toUpperCase().includes(this.searchFile.toString().toUpperCase()))
      });
    },
    getPagesAmount() {
      this.resetCurrentPage();
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
    chunkedItems() {
      let leftindex = (this.currentPage - 1) * this.itemsPerPage;
      let rightindex = leftindex + this.itemsPerPage;
      return this.filteredItems.slice(leftindex, rightindex);
    },
    leftPageBound() {
      return (this.currentPage - 1) * this.itemsPerPage + 1;
    },
    rightPageBound() {
      return (this.currentPage - 1) * this.itemsPerPage + this.itemsPerPage;
    }
  },
  watch: {
    uploadMainWidth(newWidth, oldWidth) {
      // console.log(`it changed to ${newWidth} from
      // ${oldWidth}`);
      //console.log(this.ifMDandUp);
    },
    "$store.state.windowWidth"() {
      this.onResize();
    }
  },
  async mounted() {
    await this.getHistoryData();
    if (!this.items.length) this.itemsLengthIsZero = true;
    else this.itemsLengthIsZero = false;
  },
  methods: {
    getTransactionCount(sub_transactions) {
      if (sub_transactions.length) {
        let count = 0, addressVerified = false, identityVerified = false;
        sub_transactions.forEach(item => {
          if (item.type === 'identity' && item.status === true) {
            identityVerified = true;
          }
          if (item.type === 'address' && item.status === true) {
            addressVerified = true;
          }
        })
        if (addressVerified && identityVerified) {
          count++;
          addressVerified = false;
          identityVerified = false;
        }

        return count;
      } else {
        return "N/A"
      }
    },
    itemHref(fileId) {
      return `${document.location.origin}/?fileId=${fileId}`;
    },
    itemRouterLink(fileId, chain) {
      return `/?fileId=${fileId}&chain=${chain}`;
    },
    async getHistoryData() {
      const functions = getFunctions(firebaseApp);
      const HistoryData = httpsCallable(functions, 'getAllTransactionsWithDetails');
      this.items = (await HistoryData({
        transaction: this.$route.params.id ?? null
      })).data.data;
      // console.log("Hist", hist);
    },
    onResize() {
      this.uploadMainWidth = this.$refs.uploadmain.clientWidth;
    },
    getItemsChunk() {
      this.itemsChunks;
    },
    resetCurrentPage() {
      this.currentPage = 1;
    },
    getFileNameAndType(s) {
      let name = "";
      let type = "";
      if (s === "") return s;
      for (let i = s.length - 1; i >= 0; i--) {
        if (s[i] === ".") {
          name = s.slice(0, i);
          type = s.slice(i + 1, s.length);
          return {name, type};
        }
      }
      name = s;
      return {name, type};
    },
    shortenedName(s) {
      let str = this.getFileNameAndType(s);
      if (this.ifMDandUp) {
        if (s.length > 20) {
          if (str.type == "") return str.name.slice(0, 5) + "..." + str.name.slice(-6, -1);
          else return str.name.slice(0, 5) + "..." + str.name.slice(-6, -1) + "." + str.type;
        }
      } else {
        if (s.length > 10) {
          if (str.type == "") return str.name.slice(0, 3) + "..." + str.name.slice(-3, -1);
          else return str.name.slice(0, 3) + "..." + str.name.slice(-3, -1) + "." + str.type;
        }
      }
      return s;
    },
    formatDate(s) {
      // 13/10/2021, 12:07:41
      const date = moment(s, "DD/MM/YYYY, HH:mm:ss");
      return {
        uploadDate: date.format("DD MMMM YYYY"),
        uploadTime: date.format("HH:mm")
      };
    },
    formatDateTwo(s) {
      // 13/10/2021, 12:07:41
      const date = moment(s, "YYYY-M-D, HH:mm:ss");
      return {
        uploadDate: date.format("DD MMMM YYYY"),
        uploadTime: date.format("HH:mm"),
        date: date.format("DD/MM/YYYY, HH:mm:ss")
      };
    },
    //not precise
    formatSize(size) {
      let sizes = ["B", "KB", "MB", "GB", "TB"];
      let resize = size;
      let i = 0;
      while (Math.trunc(resize).toString().length > 3) {
        resize = (resize / 1024).toFixed(1);
        //console.log(resize);
        if (i > 4) return size.toString() + " " + "B";
        i++;
      }
      return resize.toString() + " " + sizes[i];
    },
    getColor(s) {
      switch (s) {
        case "JPG":
          return "#2C5EBE";
        case "PNG":
          return "#902CBE";
        case "PDF":
          return "#BE2C35";
        default:
          return "#455666";
      }
    }
  }
};
</script>