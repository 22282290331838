<template>
  <div>
    <div>
    <DashboardHeader/>
  </div>
  <div class="login-body-div">
      <div class="auth-main">
        
        <h3 class="pb-2">Welcome Back</h3>
        <p class="font-12">Enter your credentials to access your account.</p>

        <div class="pb-2">
          <div class="pt-2 pb-2">
            <div class="error-block" v-if="(error.length > 0)">
              <div class="error-block-img"> 
                <img src="../assets/images/error-icon.svg" />
              </div>
              <span>Email or password is incorrect.</span>
            </div>
            <b-form>
              
              <b-form-input
                id="input-1"
                type="email"
                placeholder="Enter your email"
                class="form-fields mt-3"
                v-bind:class="{'input-error': error.length > 0}"
                v-model="form.email"
                v-on:change="formChanged"
              >
              </b-form-input>

              <b-form-input
                id="input-2"
                type="password"
                placeholder="Enter your password"
                class="form-fields mt-3"
                v-bind:class="{'input-error': error.length > 0}"
                v-model="form.password"
                v-on:change="formChanged"
                @keydown.enter.prevent="submit"
              >
              </b-form-input>
              
            </b-form>
          </div>
        </div>
        
        <div class="btn-div">
          <button class="main-btn" @click="submit()">
            Sign In
            <b-spinner small v-if="loading"></b-spinner>
          </button>
        </div>
      </div>
  </div>
  <FooterComDashboard/>
  </div>
  
</template>

<style scoped>
  /* .main-body-div{
    background: #F2F5FF;
  } */
  .login-body-div{
    background: #F2F5FF;
  }
  .auth-main {
    background: #FFFFFF;
    border-bottom: 1px solid rgba(185, 185, 185, 0.2);
    box-shadow: 0px 0px 15px rgb(0 0 0 / 3%);
    border-radius: 10px;
    text-align: center;
    padding: 30px;
  }
</style>

<script>
import DashboardHeader from '../components/new-components/DashboardHeader.vue';
import FooterComDashboard from '../components/new-components/FooterComDashboard.vue';
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";

export default {
  components: {
    DashboardHeader,
    FooterComDashboard
  },
  data() {
    return {
      loading: false,
      error: "",
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    formChanged: function() {
      this.error = ""
    },
    submit() {
      this.loading = true;
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.form.email, this.form.password)
      .then((res) => {
        localStorage.setItem("displayName", res.user.displayName);
        localStorage.setItem("uid", res.user.uid);
        this.login = true;
        this.$router.push({name: "overview"});
        this.loading = false;
      })
      .catch((err) => {
        this.error = err.message;
        this.loading = false;
      });
    },
  },
};
</script>
